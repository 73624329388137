<template>
	<v-container>
		<div class="text-h4 my-5">Lista de pacientes en espera</div>
		<v-row>
			<v-col cols="auto">Filtrar por sede:</v-col>
			<v-col>
				<v-select :items="[{id:0, nombre:'Todos'},...SEDES]" v-model="filtroSedes" item-text="nombre" item-value="id" class="ml-5" dense/>
			</v-col>
		</v-row>
		<v-row>
			<v-data-table :headers="headers" :items="filteredPacientes" class="elevation-1 w-full" >
				<template v-slot:item.date="{ item }">
					{{ calcDate(item.date) }}
				</template>
				<template v-slot:item.espera="{ item }">
					más de {{ calcEspera(item.date) }}
				</template>
				<template v-slot:item.nombre="{ item }">
					{{item.user.primerNombre + (item.user.segundoNombre?" "+item.user.segundoNombre:"") + " " + item.user.primerApellido+ (item.user.segundoApellido?" "+item.user.segundoApellido:"") }}
				</template>
				<template v-slot:item.triage_level="{ item }">
					<v-chip :color="item.triage ? getTriage[item.triage_level] : 'black'" dark>
						{{ item.triage ? item.triage_level  : 'N/D'}}
					</v-chip>
				</template>
				<template v-slot:item.sede="{ item }">
					{{ _.find(SEDES,['id', parseInt(item.sede)])?.nombre || item.sede || "N/D" }}
				</template>
				<template v-slot:item.admision="{ item }">
					{{item.admitido?"Realizado":"Pendiente"}}
					<v-tooltip top v-if="!item.admitido && permisos.includes('es_admision')">
						<template v-slot:activator="{on}">
							<v-btn icon v-on="on" class="primary--text" @click="modificar(item.firebaseid, 'admitido')">
								<v-icon>far fa-id-card</v-icon>
							</v-btn>
						</template>
						Admitir
					</v-tooltip>
				</template>
				<template v-slot:item.evaluacion_triage="{ item }">
					{{item.evaluacion_triage?"Realizado":"Pendiente"}}
					<v-btn icon class="primary--text" @click="tomarSV(item)"
					       v-if="!item.evaluacion_triage && permisos.includes('es_triage')">
							<v-icon>far fa-monitor-waveform</v-icon>
					</v-btn>
					<v-icon v-else-if="!item.evaluacion_triage" color="green">far fa-stethoscope</v-icon>
					<v-icon v-else color="green">far fa-check</v-icon>
				</template>

				<template v-slot:item.atendido="{ item }">
					<v-tooltip top v-if="permisos.includes('es_atender')">
						<template v-slot:activator="{on}">
							<v-btn v-on="on" icon color="green" @click="modificar(item.firebaseid, 'atendido')">
								<v-icon>fad fa-user-check</v-icon>
							</v-btn>
						</template>
						Atender
					</v-tooltip>
				</template>
				<template v-slot:item.resultados="{ item }">
					<v-tooltip top v-if="permisos.includes('es_atender')">
						<template v-slot:activator="{on}">
							<v-btn v-on="on" icon color="blue" @click="mostrarResultados(item)">
								<v-icon>fad fa-receipt</v-icon>
							</v-btn>
						</template>
						Resultados entrevista
					</v-tooltip>
				</template>
				<template v-slot:item.iconos="{ item }">
					<div class="d-flex align-center justify-space-between">
						<v-tooltip right>
							<template v-slot:activator="{on}">
								<v-icon v-if="item.score > 10" color="red" class="fa-beat-fade">fas fa-bell-on</v-icon>
								<v-icon v-else-if="item.score >= 6" color="amber">fas fa-bell</v-icon>
								<v-icon v-else-if="item.score >= 4" color="green">fas fa-bell</v-icon>
								<v-icon v-else color="blue">fas fa-calendar-lines-pen</v-icon>
							</template>
							Prioridad
						</v-tooltip>
						<v-tooltip right v-if="item.sophia">
							<template v-slot:activator="{on}">
								<img width="28" v-on="on" alt="logo sophia" src="@/assets/images/sophia_logo.png" class="mx-2">
							</template>
							Evaluado por SOPhIA
						</v-tooltip>
					</div>
				</template>
			</v-data-table>

		</v-row>
		<v-dialog v-model="resultadosDialog" persistent :max-width="$vuetify.breakpoint.mdAndUp?'60%':null">
			<v-card>
				<v-card-title>
					<h3 class="primary--text">Resumen evaluación</h3>
					<v-spacer/>
					<v-btn icon color="red" @click="resultadosDialog=false">
						<v-icon>fad fa-times</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-row no-gutters>
						<v-col cols="6">
							Edad: {{resultados?.user?.edad}}
						</v-col>
						<v-col cols="6">
							Sexo: {{_.capitalize(resultados?.user?.sexo)}}
						</v-col>
<!--						<v-col cols="6">-->
<!--							Embarazo: <v-icon :color="resultados?.user?.discapacidad?'green':'red'">fas fa-{{resultados?.user?.discapacidad?"check":"times"}}</v-icon>-->
<!--						</v-col>-->
						<v-col cols="6">
							Discapacidad: <v-icon small :color="resultados?.user?.discapacidad?'green':'red'">fas fa-{{resultados?.user?.discapacidad?"check":"times"}}</v-icon>
						</v-col>
						<v-col cols="6">
							Cancer: <v-icon small :color="resultados?.user?.cancer?'green':'red'">fas fa-{{resultados?.user?.cancer?"check":"times"}}</v-icon>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<h4 class="secondary--text">Signos Vitales</h4>
						</v-col>
						<v-col>
							<v-row no-gutters>
								<v-col cols="6" v-for="(val, key) in resultados.signosVitales" v-if="val" :key="key">
									{{_.capitalize(_.replace(key,"_"," "))}}: {{val}}
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<h4 class="secondary--text">Síntomas reportados</h4>
						</v-col>
						<v-col>
							<div v-for="ev in filteredEvidence" :key="ev.id">
								{{_.find(items,["id", ev.id])?.name||"N/D" }} {{ev.choice_id==="absent"?" - No":ev.choice_id==="unknown"?' - No sabe':ocultarNo?"":" - Si"}}
							</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-tooltip top>
						<template v-slot:activator="{on}">
							<v-btn v-on="on" outlined small @click="ocultarNo=!ocultarNo">
								{{ocultarNo?'Mostrar todos los síntomas':'Mostrar solo sintomas presentes'}}
							</v-btn>
						</template>
						<span>{{`Mostrar ${ocultarNo?'TODAS respuestas':'solo respuestas SI'}`}}</span>
					</v-tooltip>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<signos-vitales-card :firebaseId="firebaseId" v-model="evaluacionDialog"/>


	</v-container>
</template>

<script>
import {CONCEPTOS, MUTATION_RESET_INTERVIEW, PACIENTES, PERMISOS, PRO, SEDE_USER, UBICACION} from "@/store/types";
import store from "@/store";
import {mapGetters} from "vuex";
import moment from "moment";
import {updateCollection} from "@/actions/actions";
import firebase from "firebase/app";
import { SEDES } from "../store/types";
import signosVitalesCard from "../components/signosVitalesCard.vue"

export default {
	name: "ListaEspera",
	components:{ signosVitalesCard },
	data:()=>({
		pacientes: [],
		pacientesPriorizado:[],
		headers: [
			{ text: 'Fecha encuesta',  value: 'date', width: 120, sortable: false },
			{ text: 'Tiempo de espera',  value: 'espera', width: 130, sortable: false },
			{ text: 'Nombre completo',  value: 'nombre', sortable: false },
			{ text: 'Identificación',  value: 'user.identificacion', sortable: false },
			{ text: 'Contacto',  value: 'user.telefono', sortable: false },
			{ text: 'Sede',  value: 'sede', sortable: false },
			{ text: 'Admisión', align: 'center', value: 'admision' ,width: 60, sortable: false },
			{ text: 'SV Evaluado', align: 'center', value: 'evaluacion_triage' ,width: 60, sortable: false },
			{ text: 'Resumen evaluación', align: 'center', value: 'resultados' ,width: 60, sortable: false },
			{ text: 'Estados', align: 'center', value: 'iconos', sortable: false },
			{ text: 'Atender', align: 'center', value: 'atendido' ,width: 60, sortable: false },
		],
		getTriage: ["","red","deep-orange","amber","green","blue"],
		resultadosDialog: false,
		resultados:{},
		ocultarNo: true,
		filtroSedes: null,
		evaluacionDialog:false,
		firebaseId: null
	}),
	created(){
		moment.locale('es-mx')
		this.fetchPacientes();
		setInterval(this.fetchPacientes, 180000);
		this.filtroSedes = this.SEDE_USER || 0
	},
	computed:{
		...mapGetters({permisos: PERMISOS, items: CONCEPTOS, SEDES, SEDE_USER}),
		filteredEvidence(){
			if(this.resultados?.evidence){
				this.resultados.evidence.splice(_.findIndex(this.resultados.evidence, ['id', 'p_14']),1)
				if(this.ocultarNo) {
					return this.resultados.evidence.filter( item=>{
						return item.choice_id!=='unknown'&&item.choice_id!=='absent'
					} )
				}
				return this.resultados?.evidence
			}
		},
		filteredPacientes(){
			if(this.filtroSedes === 0) return this.pacientesPriorizado;
			return this.pacientesPriorizado.filter( paciente => parseInt(paciente.sede) === parseInt(this.filtroSedes) )
		}
	},
	watch:{
		pacientes: function(pacientes){
			this.pacientesPriorizado = this.sortPacientes(pacientes)
		}
	},
	methods: {
		sortPacientes(pacientes){
			return _.orderBy(pacientes, ['score','triage_level'], ['desc', 'asc']);
		},
		fetchPacientes(){
			let call = db.collection('interviews')
				.where('date', '>', Date.now()-172800000 )
				.where('terminada','==', true )
				.where('fuente','==', 'paciente' )
                .where('ubicacion','==', 'sala' )
                .where('atendido', '==',  false);

            // if(this.sedeUser) call.where('sede','==', this.sedeUser.toString() );
            
            call.onSnapshot(snapshot => {
				this.pacientes = [];
				snapshot.forEach( doc => {
					let temp = doc.data();
					temp.firebaseid= doc.id;
					let score = 0
					switch (temp.triage_level){
						case 1: score += 15; break;
						case 2: score += 13; break;
						case 3: score += 6; break;
						case 4: score += 4; break;
						default: score += 3; break;
					}

					if(temp.user.nombre) {
						temp.user.primerNombre = temp.user.nombre
						delete temp.user.nombre
						temp.user.primerApellido = temp.user.apellidos
						delete temp.user.apellidos
						console.log(temp.user)
						updateCollection('interviews', doc.id, {user: { ...temp.user, nombre: firebase.firestore.FieldValue.delete(), apellidos: firebase.firestore.FieldValue.delete() } })
					}

					if(temp.user.edad > 65 || temp.user.edad < 5) score += 1;
					if(temp.user.discapacidad) score += 1;
					if(temp.user.cancer) score += 1;

					const tiempoEspera =  Math.round((Date.now() - temp.date) / 1800000 );
					if(tiempoEspera > 1 ) score += tiempoEspera > 5 ? 5 : tiempoEspera

					temp.score = score;
					this.pacientes.push(temp);
				})
			})
		},
		calcDate(date) {
			return moment(date).format('DD MMMM YYYY - hh:mm a')
		},
		calcEspera(date) {
			return moment(date).fromNow(true)
		},
		modificar(id, item){
			swal.fire({
				title: `¿El paciente va a ser ${item}?`,
				showDenyButton: true,
				showCancelButton: true,
				confirmButtonText: 'Si',
				denyButtonText: `No`,
			}).then((result) => {
				if (result.isConfirmed) {
					updateCollection('interviews', id, {[item]: true})
					swal.fire(_.capitalize(item), '', 'success')
				} else if (result.isDenied) {
					if(item==="atendido") updateCollection('interviews', id, {"atendido": true, "admitido": false})
					swal.fire(`El paciente no ha sido ${item}`, '', 'info')
				}
			})

		},
		async triage(id){
			const { value: triage } = await swal.fire({
				title: 'Ingrese el nivel de triage',
				input: 'select',
				inputOptions: {
					1: '1 - Reanimación',
					2: '2 - Emergencia',
					3: '3 - Urgencia',
					4: '4 - Consulta Prioritaria',
					5: '5 - No urgente ',
					'Remitir': 'Remitir a otra entidad',
				},
				inputPlaceholder: 'Seleccione un nivel de triage',
				showCancelButton: true,
				cancelButtonText: 'Cancelar'
			})

			if (triage) {
				let data = {}

				if(! isNaN(parseInt(triage)) ) {
					data.triage_level = parseInt(triage);
					data.evaluacion_triage = true

					return await updateCollection('interviews', id, data)
				}
				else{
					return this.modificar(id, 'atendido')
				}
			}
		},
		mostrarResultados(item){
			if(item.signos_vitales) item.signosVitales = item.signos_vitales
			this.resultadosDialog = true
			this.resultados = item
		},
		tomarSV(item){
			this.firebaseId = item.firebaseid;
			this.evaluacionDialog =  true;
		}
	}
}
</script>

<style scoped>

</style>
