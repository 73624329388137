import { render, staticRenderFns } from "./SignosVitales.vue?vue&type=template&id=6d38c015&scoped=true"
import script from "./SignosVitales.vue?vue&type=script&lang=js"
export * from "./SignosVitales.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d38c015",
  null
  
)

export default component.exports