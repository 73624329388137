<template>
    <div>
        <div class="d-flex justify-center">
            <img :src="heroImage.src"
                 :class="{'py-15': !heroImage.showHeroImage}"
                 :style="heroImage.showHeroImage ?
			     'width:100%;':
			     'max-width:600px; max-height: 400px; width:80%;'" alt="hero image"/>
        </div>


        <v-container>
            <v-row class="mb-5" no-gutters>
                <v-col>
                    <div class="flex">
                        <div class="text-h4 mt-5 primary--text font-weight-bold" v-if="!heroImage.showHeroImage">
	                        Bienvenido {{ saludo?.[ubicacion] ?? defaultSaludo }}
                            <v-btn icon @click="read(`welcome_${ubicacion==='sala'?'sala':'default'}`)" v-if="audioDescription">
                                <v-icon>fas fa-volume</v-icon>
                            </v-btn>
                        </div>
	                    <div v-html="recomendaciones?.[ubicacion] ?? defaultRecomendacion"/>

                        <v-form ref="iniciarForm">
                            <v-row class="mt-3" no-gutters>
                                <v-col cols="12" class="my-5">
                                    <div class="primary--text text-h5">
                                        Por favor ingresa tú número de identificación<span v-if="ubicacion
								==='remoto'"> y ubicación</span> para iniciar.
                                    </div>
                                </v-col>
                                <v-col cols="12" >
                                    <v-text-field outlined label="Identificación" v-model="identificacion" placeholder="Escribe tu número de identificación" :rules="[required]" dense/>
                                </v-col>
                                <v-col cols="12" v-if="ubicacion==='remoto'">
                                    <v-select :items="sortSedes" outlined label="Sede" v-model="sede" placeholder="Cual es la sede más cercana a tu ubicación" :rules="[required]" item-value="id" item-text="nombre" dense>
                                        <template #item={item}>
                                            {{item.ciudad}} - {{item.nombre}}
                                        </template>
                                    </v-select>

	                                <v-alert type="warning">Si en estos momentos te encuentras en urgencias de {{_.find(sedes,['id',sede])?.nombre ?? 'la clínica'}}, recuerda que debes usar el código QR ubicado en sala para poder ser atendido correctamente.</v-alert>
                                </v-col>
                                <v-col cols="12" v-if="ubicacion==='sala' && identificacion.length>0">
                                    <v-checkbox v-model="checkCodigo" label="Ya he realizado la encuesta remota"
                                                class="mt-0"/>
                                </v-col>

                            </v-row>
                        </v-form>

                        <vue-recaptcha sitekey="6Lc6bvciAAAAABj1eL4vEkBzSVwxGxpiJzr6yEIo" @verify="verifyMethod" @expired="errorMethod" @error="errorMethod" v-if="captchaAvailable"/>

                        <v-checkbox v-model="politica"
                                    @mouseover.native="read('accept')" @mouseleave.native="read('stop')">
                            <template v-slot:label>
                                <div class="text-h6">
                                    Acepto la <a :href="linkPolitica" target="_blank" @click.stop>política de tratamiento de datos</a> de <b>{{cliente}}</b>
                                </div>
                            </template>
                        </v-checkbox>
	                    <v-checkbox v-model="advertencia"
	                                @mouseover.native="read('accept')" @mouseleave.native="read('stop')">
		                    <template v-slot:label>
			                    <div class="text-h6">
				                    Estoy de acuerdo con <a @click="advertenciaModal=true">ingresar la información de manera precisa.</a>
			                    </div>
		                    </template>
	                    </v-checkbox>


                    </div>
                </v-col>
            </v-row>
            <v-row class="text-center">
                <v-col>
                    <v-btn class="mx-auto" @click="iniciar" :disabled="iniciarDisable" outlined
                           @mouseover.native="read('iniciar')" @mouseleave.native="read('stop')">
                        INICIAR
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
	    <v-dialog v-model="advertenciaModal"  width="90%" >
		    <v-card>
			    <v-card-text class="pa-10">
				    <div class="font-weight-bold title secondary--text">
					    Utilizar nuestro servicio de orientación de manera precisa y detallada es esencial para garantizar el buen uso de los servicios de urgencias. Al proporcionar información clara y exacta sobre sus síntomas, nos permite orientarle al servicio adecuado. De esta manera, ayudamos a evitar la congestión innecesaria en las salas de urgencias y aseguramos que aquellos que realmente necesitan atención médica urgente reciban la atención que necesitan de manera oportuna. ¡Utilice nuestro servicio de triage electrónico con cuidado y precisión
				    </div>
			    </v-card-text>
		    </v-card>
	    </v-dialog>
    </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {

	AUDIO_DESCRIPTION, CLIENTE_NAME, CLIENTE_SHORT_NAME, INTERVIEW_USER,
	MUTATION_DISCLAIMER, MUTATION_INTERVIEW_USER, MUTATION_INTERVIEW_USER_ID,
	UBICACION, SEDES, MUTATION_SEDE, SEDE
} from "@/store/types";
import {audioDescriptionMessages, saludo, recomendaciones} from "../../../messages";
import {linkPolitica} from "../../../configs.json";
import {createCollection, updateCollection} from "@/actions/actions";
import { VueRecaptcha } from 'vue-recaptcha';
import moment from "moment";


export default {
    name: "Disclaimer",
    components: { VueRecaptcha },
    data:()=>({
        politica: false,
        linkPolitica: linkPolitica,
        identificacion: "",
        checkCodigo: false,
        codigo: "",
        captchaAvailable:process.env.VUE_APP_CAPTCHA === 'true',
        captcha: false,
        required: v=>!!v||'El campo es obligatorio',

	    advertenciaModal: false,
	    advertencia: false,

	    saludo,
	    recomendaciones,
	    defaultSaludo: 'a nuestro servicio de Teleorientación',
	    defaultRecomendacion: `
							<div class="text-h5 mt-5 primary--text mt-10 font-weight-bold ">Ten en cuenta que:</div>
			                <ul class="text-h5 mt-5 primary--text">
				                <li>Esta herramienta es orientativa y busca optimizar procesos de admisión y acceso a los servicios de salud</li>
				                <li>Se requiere veracidad y exactitud en los datos</li>
			                </ul>
			                `

    }),
    computed: {
        ...mapGetters({audioDescription: AUDIO_DESCRIPTION, ubicacion:UBICACION, paciente: INTERVIEW_USER, cliente: CLIENTE_NAME, short_name: CLIENTE_SHORT_NAME, sedes: SEDES}),

	    sede: {
			get(){
				return this.$store.getters[SEDE]
			},
		    set(val){
				return this.$store.commit(MUTATION_SEDE, val)
		    }
	    },

        heroImage: function (){
            if(process.env.VUE_APP_CLIENTE_SHORT_NAME === 'FCV'){
                if(this.$vuetify.breakpoint.mdAndUp) return {
                    showHeroImage:true,
                    src:require( "@/assets/images/teleorientacion_desktop.jpg")
                }
                else return {
                    showHeroImage:true,
                    src:require( "@/assets/images/teleorientacion_movil.jpg" )
                }
            }
            return {
                showHeroImage: false,
                src: require('@/assets/images/logo.png')
            }
        },
        iniciarDisable: function (){
            if(this.captchaAvailable) return !(this.captcha && this.politica && this.advertencia)
            return !(this.politica && this.advertencia)
        },
        sortSedes:function (){
			let sedes = _.sortBy(this.sedes,['ciudad','nombre'])
	        if(this.cliente === 'AVIDANTI' && this.ubicacion ==='remoto'){
		        this.sede = 2;
		        sedes = sedes.filter(sede => sede.id === 2)
	        }
	        return sedes
        }
    },
	watch:{
		checkCodigo: async function (val) {
			if(val){
				let userInterviews = await db.collection('interviews').where('user.identificacion', '==', this.identificacion).where('ubicacion', '==', 'remoto').orderBy('date', 'desc').limit(1).get()

				if(userInterviews.empty) {
					swal.fire({
						icon: "warning",
						text: "No hemos encontrado encuestas remotas realizadas para esta identificación"
					})
					this.checkCodigo = false;

					return
				}

				let doc =  userInterviews.docs[0].data();
				doc.firebaseId = userInterviews.docs[0].id;

				if( moment().diff(doc.date) > 28800000 ){
					swal.fire({
						icon: "warning",
						text: "Tu última encuesta fue hace más de 8 horas; deberás volver a completar una nueva encuesta"
					})
					this.checkCodigo = false;
				}
				else if(!doc.triage_level){
					swal.fire({
						icon: "warning",
						text: "La última encuesta realizada no fue completada, por favor, realiza y completa una nueva encuesta."
					})
					this.checkCodigo = false;
				}

				else{
					let message = `
							<div>Gracias, hemos registrado tu ingreso a sala e iniciaremos todos los procesos para facilitar tu atención.</div>
							<div>Queda atento al llamado.</div>`

					if(process.env.VUE_APP_CLIENTE_SHORT_NAME==='AVIDANTI'){
						const custom = await import ("@/actions/custom");
						const turno = await custom.netuxTurn(this.sede, doc.triage_level, doc.user, "sala");
						message += `<div>Tu turno es ${turno}.</div>`;
					}



					updateCollection("interviews", doc.firebaseId, {ubicacion: "sala"}).then(()=>{
						swal.fire({
							icon: "success",
							html: message
						})
					})
				}
			}
		}
	},
	methods:{
        ...mapMutations({setDisclaimer: MUTATION_DISCLAIMER,setInterviewUser: MUTATION_INTERVIEW_USER, setPacienteId: MUTATION_INTERVIEW_USER_ID, setSede: MUTATION_SEDE}),
        iniciar: async function(){
            if(!this.$refs.iniciarForm.validate()) return false
            if(this.ubicacion==='remoto') this.setSede(this.sede)
            this.setDisclaimer(true);
            let userInterviews = await db.collection('pacientes').where('identificacion', '==', this.identificacion).get()

            if(userInterviews.empty){
                this.setInterviewUser({ identificacion: _.trim(this.identificacion) })
                const id = await createCollection('pacientes', this.paciente)
                this.setPacienteId(id)

            }
            else{
                this.setInterviewUser(userInterviews.docs[0].data())
                this.setPacienteId(userInterviews.docs[0].id)
            }

            return this.$router.push({name: 'DatosPaciente'})
        },
        read(item){ readDescription(item) },
        verifyMethod(val){
            if(val) this.captcha = true
        },
        errorMethod(){ this.captcha = false }
    }
}
</script>

<style>
</style>
