<template>
	<v-card flat>
		<v-card-title class="secondary--text pregunta">
			<h2>{{pregunta.text}}</h2>
		</v-card-title>
		<v-card-subtitle>
			* Debe responder todas las preguntas
		</v-card-subtitle>
		<v-card-text>
			<v-form id="pregMultForm" ref="pregMultForm">
				<v-row  align="center" v-for="(item,i) in pregunta.items" :key="`preg_mult_${i}`" no-gutters>
					<v-col :cols="$vuetify.breakpoint.smAndDown?'12':''" ><h3>{{item.name}}</h3></v-col>
					<v-col cols="auto">
						<v-radio-group v-if="show[i]" v-model="respuesta[i].choice_id" row :rules="rules.required">
							<v-radio label="Si" value="present"></v-radio>
							<v-radio label="No se" value="unknown"></v-radio>
							<v-radio label="No" value="absent"></v-radio>
						</v-radio-group>
					</v-col>
					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn outlined color="primary" @click="siguiente" v-if="!terminar">Siguiente</v-btn>
			<v-btn v-if="terminar||emergencia" outlined color="accent" @click="enviar">Terminar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import validationRules from "@/actions/validationRules";
import {mapActions, mapGetters} from 'vuex';
import * as types from '@/store/types'
import {ADD_EVIDENCE, ADD_MULTIPLE_EVIDENCE} from "@/store/types";

export default {
	name: "preguntasMultiple",
	props:{ pregunta: {required: true}, terminar: {required:true}, emergencia:{required:true}},
	data: ()=>({
		respuesta: [],
		show:[],
		rules: validationRules,
		pregMultForm:false,
	}),
	computed:{
		...mapGetters({ evidence: types.EVIDENCE})
	},
	created() {
		this.setup();
	},
	mounted() {
		this.$refs.pregMultForm.reset();
	},
	watch:{
		pregunta: function(){ this.setup(); }
	},
	methods:{
		...mapActions({addEvidence: ADD_EVIDENCE, addMultipleEvidence:ADD_MULTIPLE_EVIDENCE}),

		setup(){
			cargando(true)
			this.show=[];
			this.respuesta = [];
			this.pregunta.items.forEach((item, index) =>{
				this.respuesta.push({ id: item.id, choice_id: ""})
				this.show[index]=true
				cargando(false)
			})
		},
		siguiente(){
			if(this.$refs.pregMultForm.validate()){
				cargando(true);
				this.addMultipleEvidence(this.respuesta).then(()=>{
					Event.$emit('respuesta', this.respuesta);
					this.setup()
				})
				cargando(false);
			}
		},
		enviar(){
			if(this.$refs.pregMultForm.validate()) {
				cargando(true);
				this.addMultipleEvidence(this.respuesta)
					.then(() => {
						Event.$emit('resultado'); cargando(false);
					})
				cargando(false);
			}
		}
	}
}
</script>

<style scoped>

</style>
