import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'
import credentials from '../../configs.json'

// Initialize Firebase
firebase.initializeApp(credentials.firebaseConfig);
firebase.analytics();
window.db = firebase.firestore();
window.auth = firebase.auth();

if (location.hostname === "localhost") {
	db.useEmulator("localhost", 8085);
	auth.useEmulator("http://localhost:9099");
}
