import * as types from '../types'

const state = {
	cliente: process.env.VUE_APP_CLIENTE_NAME,
	cliente_corto: process.env.VUE_APP_CLIENTE_SHORT_NAME,
	api_url: process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_API_URL_LOCAL:process.env.VUE_APP_API_URL,
	app_url: '',
	pacientes: true,
	pro: true,
	// ubicacion: process.env.NODE_ENV === 'development' ? 'signosVitales': null,
	ubicacion: "remoto",
	sede: 1,
	sedes:[],
	audio_description: false,
	cabina: 1,
	rutasPaciente: false,
	dev: process.env.NODE_ENV === 'development',
	devLocations: ['sala', 'signosVitales', 'remoto']
};

const getters = {
	[types.DEV]: state => { return state.dev; },
	[types.DEV_LOCATIONS]: state => { return state.devLocations; },
	[types.UBICACION]: state => { return state.ubicacion; },
	[types.SEDE]: state => { return state.sede; },
	[types.SEDES]: state => { return state.sedes; },
	[types.CABINA]: state => { return state.cabina; },
	[types.CLIENTE_NAME]: state => { return state.cliente; },
	[types.CLIENTE_SHORT_NAME]: state => { return state.cliente_corto; },
	[types.API_URL]: state => { return state.api_url; },
	[types.APP_URL]: state => { return state.app_url; },
	[types.PACIENTES]: state => { return state.pacientes; },
	[types.RUTA_PACIENTES]: state => { return state.rutasPaciente; },
	[types.PRO]: state => { return state.pro; },
	[types.AUDIO_DESCRIPTION]: state => { return state.audio_description; },

};

const mutations = {
	[types.MUTATION_UBICACION]: (state, payload) => {state.ubicacion= payload},
	[types.MUTATION_SEDE]: (state, payload) => {state.sede= payload},
	[types.MUTATION_SEDES]: (state, payload) => {state.sedes= payload},
	[types.MUTATION_CABINA]: (state, payload) => {state.cabina= payload},
	[types.MUTATION_PACIENTES]: (state, payload) => {state.pacientes= payload===undefined?true:payload},
	[types.MUTATION_RUTA_PACIENTES]: (state, payload) => {state.rutasPaciente= payload===undefined?false:payload},
	[types.MUTATION_PRO]: (state, payload) => { state.pro = payload===undefined?true:payload},
	[types.MUTATION_AUDIO_DESCRIPTION]: (state) => { state.audio_description = !state.audio_description },
};

const actions = {};

export default {
	state,
	getters,
	mutations,
	actions
}
