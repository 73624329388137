import Vue from "vue";

Vue.filter('toCurrency', function (val) {
	if (typeof parseInt(val) !== "number" || isNaN(val)) {  val = _.replace(val, /[^\d.]/g, ''); }

	let formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0
	});
	return formatter.format(parseInt(val));
});

Vue.filter('toDate', function (val, hour=true) {
	let options = { year: 'numeric', month: 'numeric', day: 'numeric'};
	if(hour){
		options.hour12 = true
		options.hour = 'numeric'
		options.minute = 'numeric'
	}
	return _.replace(new Date(val).toLocaleString('co', options), ' ','\n')
});
