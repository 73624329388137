<template>
	<div :class="classes" class="pt-2" >
		<v-img v-if="img" :src="img" class="mb-3" :class="{ 'mx-auto': !horizontal}" contain :max-height="horizontal ? 80:100" :max-width="horizontal ? 100: 'auto'"/>

		<div :class="horizontal && title && 'ml-6'">
			<div class="subtitle-1 text-uppercase mv-3" :v-text="title"/>
			<div v-if="text || $slots.default" :class="[{'mb-0': horizontal},{'text-center': !horizontal}]" v-text="text" style="max-width:700px">
				<slot />
			</div>
			<div class="text-center mt-3">
				<v-btn v-if="title ==='Marco'" outlined :to="{name: 'Register'}">Mas Información</v-btn>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'AvatarCard',
	props: {
		align: { type: String, default: 'left' },
		dark: Boolean,
		horizontal: Boolean,
		img: String,
		outlined: { type: Boolean, default: true},
		space: { type: [Number, String], default: 8 },
		size: { type: [Number, String], default: 72 },
		text: String,
		title: String,
	},

	computed: {
		classes () {
			const classes = [ `mb-${this.space}` ]

			if (this.horizontal) {
				classes.push('d-flex')
				if (!this.$slots.default && !this.text) { classes.push('align-center') }
			}

			return classes
		},
	},
}
</script>