<template>
	<v-container>
		<v-card v-if="!!resultados.triage_level">
			<v-card-title>
				<div class="text-h4 primary--text">Resultado</div>
			</v-card-title>
			<v-card-text>
                <div class="text-h5" v-html="mensajes[ubicacion][resultados.triage_level].text"/>

                <template v-if="resultados.triage_level < 3 && ubicacion==='remoto'">
                    <div>
                        Su código para atención en clínica es: {{codigo}}
                    </div>
                    <div class="d-flex justify-center absolute mt-3">
                        <canvas id="qrcode"/>
                    </div>
                </template>

				<v-alert type="info" v-if="ubicacion!=='remoto'">Esta ventana se cerrara en {{timer}} segundos</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" @click="reiniciar" dark outlined>nueva consulta</v-btn>
			</v-card-actions>
		</v-card>



	</v-container>
</template>

<script>
import * as types from "@/store/types";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
    CABINA,
    FIREBASE_ID,
    INTERVIEW_USER,
    MUTATION_DISCLAIMER, MUTATION_FIREBASE_ID,
    MUTATION_RESULTADOS, MUTATION_UBICACION,
    RESET_INTERVIEW,
    RESULTADOS, SEDE
} from "@/store/types";
import {mensajesSalida} from "../../../../messages";
import QRious from 'qrious'
import axios from "axios";
import configs from "../../../../configs.json";


export default {
	name: "ResultadosPaciente",
	data:()=>({
		cabinas: configs.cabinas,
		telefono: "",
		mensajes:mensajesSalida,
		diagnosticosDisplay: false,

		getCanalEspecialista:{
			audio_teleconsultation: "Llamada telefónica",
			video_teleconsultation: "Video llamada",
			text_teleconsultation: "Chat",
			personal_visit: "Presencial",
		},
		timer: 15,

		dev_firebase_id: 'vpzVoHkREVsFSUGnTDEL',
		dev_ubicacion: 'signosVitales',
		dev_time_reset: false,
	}),
	created: async function() {
		if(process.env.NODE_ENV === 'development') {
			const docRef = await db.collection('interviews').doc(this.dev_firebase_id)
			this.$store.commit(MUTATION_FIREBASE_ID, this.dev_firebase_id)
			const doc = await docRef.get();
			if(doc.exists) {
                this.$store.commit(MUTATION_RESULTADOS, doc.data())
				this.$store.commit(MUTATION_UBICACION,this.dev_ubicacion)
            }
		}

		if((process.env.NODE_ENV === 'production' && this.ubicacion !== 'remoto') || this.dev_time_reset)
			setInterval(() => { this.timer --; }, 1000)



	},
	mounted() {
        if(this.ubicacion!=="signosVitales"){
            setTimeout(()=>{
                new QRious({
                    element: document.getElementById("qrcode"),
                    background: '#ffffff',
                    backgroundAlpha: 1,
                    foreground: '#5868bf',
                    foregroundAlpha: 1,
                    level: 'H',
                    padding: 0,
                    size: 128,
                    value: this.firebaseId||"No code"
                });
            },1000)
        }
		else{
            setTimeout(()=>{this.callVideo()},1000)
        }
	},
	computed: {
		...mapGetters({
			resultados: RESULTADOS,
			ubicacion: types.UBICACION,
			interviewUser:INTERVIEW_USER,
			firebaseId: FIREBASE_ID,
			cabina: CABINA,
			sede:SEDE,
			cliente: process.env.VUE_APP_CLIENTE_SHORT_NAME
		}),
		codigo: function(){return this.firebaseId||'sin código'}
	},
	watch:{
		timer: function (val) { if(val === 0 )this.reiniciar(true) }
	},
	methods:{
		...mapMutations({ setDisclaimer: MUTATION_DISCLAIMER }),
		...mapActions({ resetInterview: RESET_INTERVIEW }),

		callVideo:function (){
            axios.post(`https://${this.cabinas[this.cabina]}/video/`, {"start": true});
		},
		reiniciar: async function(){
			this.resetInterview(true);
			this.setDisclaimer(false);
            const query= { u:this.ubicacion, s: this.sede};
            if(this.cabinas)query.c = this.cabinas;
			return this.$router.push({name:'Prepaciente', query:query});
		}
	}
}
</script>

<style scoped>

</style>
