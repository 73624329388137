import firebase from "firebase/app";
import "firebase/auth"
import "firebase/storage"
import router from "@/router"
import store from "@/store"
import * as types from "@/store/types"
import 'lodash'
import {ADD_EVIDENCE, EVIDENCE, FIREBASE_ID, LOGOUT, REMOVE_EVIDENCE, SIGNOSVITALES} from "@/store/types";
import _ from "lodash";

export function getCleanedString(cadena){
	if (cadena){
		// Definimos los caracteres que queremos eliminar
		var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";

		// Los eliminamos todos
		for (var i = 0; i < specialChars.length; i++) {
			cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
		}

		// Lo queremos devolver limpio en minusculas
		cadena = cadena.toLowerCase();

		// Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
		cadena = cadena.replace(/ /g,"_");

		// Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
		cadena = cadena.replace(/á/gi,"a");
		cadena = cadena.replace(/é/gi,"e");
		cadena = cadena.replace(/í/gi,"i");
		cadena = cadena.replace(/ó/gi,"o");
		cadena = cadena.replace(/ú/gi,"u");
		cadena = cadena.replace(/ñ/gi,"n");
		return cadena;
	}
	else return ''

}

export async function refresh_token(){
	const token = await auth.currentUser.getIdToken()
	return store.commit(types.MUTATION_TOKEN, token);
}

export function createCollection(collection, data){
	return db.collection(collection).add( _.omit(data, ['imagen']) )
		.then(data =>{ return data.id; })
		.then(id => {
			if(data.imagen){ uploadImage(collection, id, data) }
			else{ return id }
		})
}

export function updateCollection(collection, id, data){
	return db.collection(collection).doc(id).set( _.omit(data, ['imagen']), {merge:true} )
		.then(()=>{
			if(data.imagen){ uploadImage(collection, id, data) }
			else{ return true }
		})
}

export function deleteCollection(collection,id){
	return db.collection(collection).doc(id).delete()
		.then(() => { return 'Eliminado' })
		.catch(function(error) { console.error("Error: ", error); });
}

export function uploadImage(collection, id, data, justUpload=false) {
	let imageUrl;
	const filename = data.imagen.name;
	const ext = filename.slice(filename.lastIndexOf('.'));
	return firebase.storage().ref(`${collection}/${id}.${ext}`).put(data.imagen)
		.then(fileData=>{ return fileData.ref.getDownloadURL();})
		.then(imageUrl=>{
			if (justUpload) { return imageUrl; }
			else {
				return db.collection(collection).doc(id).update({imagen: imageUrl})
					.then(()=>{
						return imageUrl
					})
			}
		} )
		.catch(error=>{});
}

export const assignPermisos = (uid, permisos) => {
	return db.collection('users').doc(uid).set( { permisos } )
};

export const logout =async ()=>{
	await store.dispatch(LOGOUT);
	await localStorage.removeItem('vuex');

	if(firebase.auth().currentUser.isAnonymous){ await firebase.auth().currentUser.delete() }
	await firebase.auth().signOut()
	return router.push({name: 'Login'})
};

export function fetchOptions(options) {
	let headers = { 'Accept':'application/json', 'Content-Type':'application/json' }
	if(options.headers) {
		if(Array.isArray(options.headers)){ options.headers.forEach(header =>{
			Object.assign(headers, header)
		}) }
		else{ Object.assign(headers, options.headers); }
	}
	if (options.auth) headers.Authorization = 'Bearer ' + store.getters[types.TOKEN];
	let temp = { headers: headers }
	if(options.method){ temp.method = options.method; }
	else { temp.method = 'GET' }
	if(options.body){ temp.body = JSON.stringify(options.body); }
	if(options.signal){ temp.signal = options.signal; }
	return temp;
}

export async function triageFunction(firebaseId, api_url, finalizar=true){
	const doc = await db.collection('interview').doc(firebaseId).get();
	if(doc.exists){
		const docData = doc.data()
		const evidence = docData.evidence
		const interviewId = docData.id

		let triage = {
			mode: "triage",
			evidence: evidence
		}

		let specialist = {
			mode: "recommend_specialist",
			evidence: evidence
		}

		let triageData= {}
		if(finalizar) { triageData.terminada= true 	}



		const p1=  fetch(`${api_url}interview/${interviewId}`, fetchOptions({method: 'POST', auth, body: triage}))
			.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })

		const p2=  fetch(`${api_url}interview/${interviewId}`, fetchOptions({method: 'POST', auth, body: specialist}))
			.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })

		Promise.all([p1,p2]).then(values=>{
			const getTriage ={
				emergency_ambulance: 1,
				emergency:2,
				consultation_24:3,
				consultation:4,
				self_care:5
			};

			triageData.triage_level= getTriage[values[0].result.triage_level]
			triageData.triage=  values[0].result
			triageData.specialist= values[1].result

			updateCollection('interviews', firebaseId, triageData)

		})
			.catch(e=>{
				Promise.resolve(e).then(error=>{
					if(JSON.parse(error).message === 'Unauthorized' ){ refresh_token(); this.triageFunction(evidence, firebaseId, interviewId, api_url, finalizar) }
				});
				console.log(e);
			})
	}
}

export async function analizarSignos(signosVitales, firebaseId = store.getters[FIREBASE_ID]){
	const criteriosSignosVitales = store.getters[SIGNOSVITALES];

	for (const id of ["s_534", "s_2169", "s_261", "s_410", "s_2168", "s_99", "s_100", "s_2000", "s_533", "s_2272", "s_543", "s_2408", "s_1933", "s_2389", "s_2388", "s_2387", "s_2376", "s_227"]) {
		await store.dispatch(REMOVE_EVIDENCE, id)
	}

	for (const signoVital in criteriosSignosVitales) {
		const data = Object.entries(criteriosSignosVitales[signoVital])

		for (const [conceptId, values] of  _.sortBy(data, [ i => i[1][0] ]) ) {
			const referencia = values[0]
			const signo = values[1]
			const valor = parseFloat(signosVitales[signoVital])

			if (
				(signo === 'mayor' && valor > referencia) ||
				(signo === 'menor' && valor < referencia) ||
				(signo === 'menor o igual' && valor <= referencia) ||
				(signo === 'mayor o igual' && valor >= referencia)
			) {
				if (['s_2272', 's_543', 's_1933', 's_533', 's_2408'].includes(conceptId)) {
					if( ! await checkPresion(conceptId) ) return false
				}

				await store.dispatch(ADD_EVIDENCE, {"id": conceptId, "source": "initial", "choice_id": "present"} )
				break;
			}
		}
	}

	if(firebaseId)
		updateCollection('interviews', firebaseId, {signosVitales, source_sv: "manual"})
}

async function checkPresion(concept_id) {
	const evidence = store.state.evidence

	if (concept_id === 's_2272') {
		if (_.find(evidence, ['id', 's_543']))  return false;
		if (_.find(evidence, ['id', 's_1933'])) return false;
		if (_.find(evidence, ['id', 's_533'])) return false;
		if (_.find(evidence, ['id', 's_2408'])) return false;
	}

	if (concept_id === 's_1933') {
		for (const id of ["s_543", "s_2272"]) {
			await store.dispatch(REMOVE_EVIDENCE,id)
		}
	}

	if (concept_id === 's_543') {
		if (_.find(evidence, ['id', 's_1933'])) return false
		await store.dispatch(REMOVE_EVIDENCE,"s_2272")
	}

	if (concept_id === 's_533' || concept_id === 's_2408') {
		await store.dispatch(REMOVE_EVIDENCE,"s_2272")
	}

	return true
}

export function imc(peso,altura){
	return (peso / Math.pow(altura/100,2)).toFixed(2)
}
