<template>
	<v-form v-model="dataForm" ref="dataForm">
		<v-row justify="center" >
			<v-col cols="11" md="10">
				<h2 class="secondary--text">¿Ha viajado en los últimos 15 días a alguno de estos sitios?</h2>
				<p>Solo seleccione los sitios donde ha estado</p>
			</v-col>
			<v-col cols="11" md="10">
				<v-card>
					<v-card-text>
						<v-checkbox v-model="value.north_america" label="Norte América (excluido Méjico)" hide-details></v-checkbox>
						<v-checkbox v-model="value.europe" label="Europa" hide-details></v-checkbox>
						<v-checkbox v-model="value.north_africa" label="África del Norte" hide-details></v-checkbox>
						<v-checkbox v-model="value.central_africa" label="África Central" hide-details></v-checkbox>
						<v-checkbox v-model="value.south_africa" label="África del Sur" hide-details></v-checkbox>
						<v-checkbox v-model="value.oceania" label="Australia y Oceanía" hide-details></v-checkbox>
						<v-checkbox v-model="value.russia" label="Rusia, Kazajistán y Mongolia" hide-details></v-checkbox>
						<v-checkbox v-model="value.middle_east" label="Oriente Medio" hide-details></v-checkbox>
						<v-checkbox v-model="value.asia" label="Asia (excluyendo Rusia, Kazajistán y Mongolia)" hide-details></v-checkbox>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn outlined color="primary" @click="siguiente">Siguiente</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
export default {
	name: "viajes",
	props: { value: {required: true} },
	data:()=>({
		dataForm: false,
	}),
	methods:{
		siguiente(){
			Event.$emit('siguienteStart', 'interview')
		}
	}
}
</script>

<style scoped>

</style>
