<template>
	<v-card flat>
		<v-card-title class="secondary--text pregunta">
			<h2>{{pregunta.text}}</h2>
		</v-card-title>
		<v-card-text>
			<v-form id="pregSingForm" ref="pregSingForm">
				<v-radio-group v-if="show" v-model="respuesta.choice_id" :rules="rules.required">
					<v-radio label="Si" value="present"></v-radio>
					<v-radio label="No se" value="unknown"></v-radio>
					<v-radio label="No" value="absent"></v-radio>
				</v-radio-group>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn outlined color="primary" @click="siguiente" v-if="!terminar">Siguiente</v-btn>
			<v-btn v-if="terminar||emergencia" outlined color="accent" @click="enviar">Terminar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import * as types from '@/store/types'
import validationRules from "@/actions/validationRules";
import {ADD_EVIDENCE, ADD_MULTIPLE_EVIDENCE} from "@/store/types";

export default {
	name: "preguntasSingle",
	props: { pregunta: {required: true}, terminar: {required:true}, emergencia:{required:true} },
	data:()=>({
		respuesta: { id: '', choice_id: ''},
		rules: validationRules,
		show: false
	}),
	computed:{
		...mapGetters({ api_url: types.API_URL })
	},
	mounted() { this.setup(this.pregunta) },
	watch:{
		pregunta: function (value){ this.setup(value) }
	},
	methods:{
		...mapActions({addEvidence: ADD_EVIDENCE, addMultipleEvidence:ADD_MULTIPLE_EVIDENCE}),

		setup(value){
			cargando(true)
			this.$refs.pregSingForm.reset();
			this.respuesta.id=value.items[0].id
			this.show=true
			cargando(false)
		},
		siguiente(){
			if(this.$refs.pregSingForm.validate()){
				cargando(true);
				this.addEvidence(this.respuesta).then(()=>{
					this.show=false
					Event.$emit('respuesta', this.respuesta);
					this.respuesta = { id: '', choice_id: ''};
				})
				cargando(false);
			}
		},
		enviar(){
			if(this.$refs.pregSingForm.validate()) {
				cargando(true);
				this.addEvidence(this.respuesta).then(() => {
					Event.$emit('resultado');
				})
				cargando(false);
			}
		}
	}
}
</script>

<style scoped>

</style>
