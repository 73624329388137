import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/plugins/firebaseConfig';
import '@/plugins/common';

import firebase from 'firebase/app'
import 'firebase/auth'
import '@/sass/app.scss'

import swal from "sweetalert2";
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {refreshOnceOnNavigation: true,})

import '@/plugins/filters'

window.Event = new Vue();
window.swal = swal;
window.toast = swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 5000 });
window.cargando = status => { Event.$emit('cargando', status)}
window.readDescription = item => { Event.$emit('readDescription', item) }

Vue.set(Vue.prototype, 'localDev', ()=>process.env.NODE_ENV==='development');
Vue.set(Vue.prototype, '_', _);

Vue.config.productionTip = false

let app;

firebase.auth().onAuthStateChanged(()=> {
	if (!app) {
		app = new Vue({
			router,
			store,
			vuetify,
			render: h => h(App)
		}).$mount('#app')
	}
})
