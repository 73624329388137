<template>
	<div class="fill-height">
		<v-row  class="fill-height"  no-gutters>

			<!--	DATOS BASICOS 	-->
			<v-navigation-drawer clipped :mini-variant.sync="mini" width="400px" permanent touchless>
				<v-tooltip v-if="!mini" color="primary" left>
					<template v-slot:activator="{on}">
						<v-btn @click="mini = !mini" icon v-on="on" fab absolute right class="mt-2">
							<v-icon color="primary">fad fa-chevron-double-left</v-icon>
						</v-btn>
					</template>
					<span>{{ mini ? 'Maximizar':'Minimizar' }}</span>
				</v-tooltip>
				<slot name="informacion"/>
				<template v-slot:append v-if="!terminada">
					<v-container>
						<v-row v-if="!mini" class="px-2">
							<slot name="signosVitalesInicio"/>
						</v-row>
					</v-container>
				</template>
				<slot name="scanIdDialog"/>
			</v-navigation-drawer>

			<!--	SELECTOR DE SINTOMAS 	-->
			<v-col cols="4" class="fill-height" :style="{'max-width': miniSintomas?'fit-content':'33.3333333333%'}" v-if="!terminada || dev">
				<slot name="selectorsintomas"/>
			</v-col>

			<!--	CONFIRMACION DE SINTOMAS 	-->
			<v-col cols="auto">
				<v-sheet class="fill-height" elevation="2">
					<slot name="sintomas"/>
					<slot name="botonesSintomas"/>
				</v-sheet>
			</v-col>

			<!--	PREGUNTAS Y RESULTADOS 	-->
			<v-col class="fill-height" ref="resultadoContainer" >
				<div class="d-flex flex-column" >
					<v-card class="overflow-y-auto d-flex" elevation="2" :style="{'height': height}" v-if="!terminada">
						<slot name="preguntas"/>
					</v-card>

					<v-card :style="{'height': height}" class="overflow-y-auto">
						<slot name="PDF"/>

						<v-tooltip bottom color="primary">
							<template v-slot:activator="{on}">
								<v-btn color="primary" icon v-on="on" absolute right fab @click="resultsDialog=true">
									<v-icon>far fa-expand-wide</v-icon>
								</v-btn>
							</template>
							<span>Pantalla completa</span>
						</v-tooltip>

						<v-dialog v-model="resultsDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
							<v-card>
								<v-toolbar dark color="primary">
									<v-btn icon dark @click="resultsDialog = false">
										<v-icon>mdi-close</v-icon>
									</v-btn>
									<v-toolbar-title>Resultados preliminares</v-toolbar-title>
								</v-toolbar>
								<slot name="resultados"/>
							</v-card>
						</v-dialog>
						<slot name="resultados"/>
					</v-card>
				</div>
			</v-col>
		</v-row>
		<slot name="sintomaSugerido"/>
		<slot name="consultaResultados"/>
	</div>
</template>

<script>

export default {
	name: "ProTemplate",
	data:()=>({
		dev: process.env.NODE_ENV === 'development',
		mini: false,
		miniSintomas: false,
		resultsDialog:false,
		iniciado: false,
		terminada: false,
	}),
	created() {
		Event.$on('sintomasState', (e)=> this.miniSintomas = e )
		Event.$on('terminada', (e)=> this.terminada = e )
	},
	computed:{
		height(){
			if(this.terminada) return (window.innerHeight -67) + 'px'
			return (window.innerHeight -67) / 2   + 'px'
		}
	},
	watch:{
		mini: function(val){ Event.$emit('mini', val) }
	}
}
</script>

<style scoped>

</style>
