<template>
	<v-card>
		<v-card-text class="pt-5">
			<v-form ref="traumaForm">
				<div class="text-h5 primary--text">Lesión física</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'detail'})" :key="`detail_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>

				<div class="text-h5 primary--text mt-5">Ubicación</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'location'})" :key="`location_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>
				<div class="text-h5 primary--text mt-5">Síntomas</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'symptom'})" :key="`symptom_${n}`" v-if="!!concept.condition ? getVariable(concept.condition) : true">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>
				<div class="text-h5 primary--text mt-5">Factores de Riesgo</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'risk_factor'})" :key="`risk_factor_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>
			</v-form>

		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn outlined color="primary" @click="enviar()">enviar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "trauma",
	data:()=>({
		concepts:{
			p_264: {text: 'Lesión reciente', value: false, category: 'detail'},
			p_258: {text: 'Politraumatismo', value: false, category: 'detail'},
			s_44: {text: 'Dolor articular', value: false, category: 'detail'},
			s_41: {text: 'Dolor articular, grave, posterior a traumatismo', value: false, category: 'detail'},
			s_2089: {text: 'Lesión severa, dificultad respiratoria, sangrado abundante, nuevo problema neurológico', value: false, category: 'detail'},

			p_260: {text: 'Cabeza', value: false, category: 'location'},
			p_240: {text: 'Cuello', value: false, category: 'location'},
			p_259: {text: 'Tronco', value: false, category: 'location'},
			p_245: {text: 'Cadera', value: false, category: 'location'},
			p_241: {text: 'Pelvis', value: false, category: 'location'},

			p_255: {text: 'Hombro', value: false, category: 'location'},
			p_254: {text: 'Brazo', value: false, category: 'location'},
			p_252: {text: 'Antebrazo', value: false, category: 'location'},
			p_253: {text: 'Codo', value: false, category: 'location'},
			p_250: {text: 'Mano', value: false, category: 'location'},
			p_251: {text: 'Muñeca', value: false, category: 'location'},
			p_169: {text: 'Dedo', value: false, category: 'location'},

			p_256: {text: 'Pierna', value: false, category: 'location'},
			p_244: {text: 'Rodilla', value: false, category: 'location'},
			p_247: {text: 'Pantorrilla', value: false, category: 'location'},
			p_246: {text: 'Pie', value: false, category: 'location'},

			s_700: {text: 'Hinchazón', value: false, category: 'symptom'},
			s_1960: {text: 'Moretón', value: false, category: 'symptom'},
			s_1340: {text: 'Extensión refleja (Signo de Westphal)', value: false, category: 'symptom', condition: 'rodilla'},
			s_1713: {text: 'Rango de movimiento articular disminuido', value: false, category: 'symptom'},
			s_2087: {text: 'Deformidad articulación', value: false, category: 'symptom'},
			s_2181: {text: 'Deformidad hueso', value: false, category: 'symptom'},
			s_2086: {text: 'Incapacidad de aguantar peso', value: false, category: 'symptom'},
			s_241: {text: 'Cambios dermatológicos', value: false, category: 'symptom'},
			s_2377: {text: 'Herida en la piel', value: false, category: 'symptom'},

			p_272: {text: 'Antecedentes familiares de problemas de la coagulación', value: false, category: 'risk_factor'},
		}
	}),
	computed:{
		rodilla() {
			if(this.concepts) return this.concepts['p_244'].value;
			else return false;
		},
		moreton() {
			if(this.concepts) return this.concepts['s_1960'].value;
			else return false;
		}
	},
	methods: {
		enviar(){
			if(this.$refs.traumaForm.validate()){
				let evidence = [{"id": "s_2088","choice_id": "present"}]
				_.forIn(this.concepts, function(values, key) {
					evidence.push({"id": key, "choice_id": values.value?"present":"absent"})
				});
				this.$emit("input", evidence)
			}
		},
		getVariable(data){
			return this[data];
		}

	}
}
</script>

<style scoped>

</style>
