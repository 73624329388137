export default {
    required: [v => !!v || 'El campo es obligatorio'],
    email: [
        v => !!v || 'El E-mail es obligatorio',
        v => /.+@.+\..+/.test(v) || 'El E-mail es invalido',
    ],
    password: [
        v => !!v || 'La contraseña es obligatoria',
        v => (v && v.length >= 6) || 'No puede menos de 6 caracteres',
        v => (v && v.length <= 16) || 'No puede excederse de 16 caracteres',
    ],
    subdominio: [
        v => !!v || 'El campo es obligatorio',
        v => (v && v.length >= 3) || 'No puede menos de 3 caracteres',
        v => (v && v.length <= 25) || 'No puede excederse de 25 caracteres',
        v => !/[^a-zA-Z0-9]/.test(v) || "No use espacios, puntos, comas o símbolos",
        v => /^[a-z0-9]/.test(v) || "No puede iniciar con mayúsculas, espacios o símbolos"
    ],
    noSimbolo:[
        v => !!v || 'El campo es obligatorio',
        v => !/[^a-zA-Z0-9\s\u00C1-\u00FF]/.test(v) || 'No use puntos, comas o símbolos'
    ],
    numero: [
        v => !!v || 'El campo es obligatorio',
        v => !/[^0-9.]/.test(v) || 'Solo números, no use letras, espacios, comas o símbolos',
    ],
    phone:[
        v => !!v || 'El campo es obligatorio',
        v => !/[^0-9+]/.test(v) || 'Solo números, no use letras, espacios, puntos, comas o símbolos',
        v => /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})?/.test(v) || 'Formato no valido',
        v => (v && v.length < 15) || 'No puede excederse de 15 caracteres',
    ]
};
