<script>
import Vue from "vue";

export default {
	data:()=>({
		gateway: "",
		gateways:[],
		listening: false,
		unsubscribeFn: null,
		measure: {}
	}),
	created() {
		this.getGateways()
	},
	methods: {
		getGateways() {
			db.collection('configs').doc("gateways").get()
				.then(doc => {
					if (doc.exists) this.gateways = Object.values(doc.data());
				})
				.finally(() => {
					this.loading = false;
				})

		},
		async activateListener() {
			this.listening = true

			const docRef = db.collection('glyaMeasures').doc(this.gateway)
			await docRef.set({pacient_id: ''})

			this.unsubscribeFn = docRef.onSnapshot( doc=>{
				const data = doc.data()

				if(data.measure){
					this.measure =  data.measure
				}
			} )
		},
		unsubscribe(){
			this.unsubscribeFn();
			this.listening = false
		}
	}

}

</script>

<template>
 <div class="container">
	 <v-card>
		 <v-card-text>
			 <v-select :items="gateways" v-model="gateway" item-text="nombre" item-value="serial" label="Seleccione el gateway a probar" hide-details/>

		 </v-card-text>
		 <v-card-actions>
			 <v-row>
				 <v-col class="d-flex flex-column mt-2" v-if="listening">
					 <div class="mx-auto">Esperando resultados de los signos vitales</div>
					 <v-progress-circular  class="mx-auto"
					                       indeterminate
					                       color="primary"
					 />
					 <v-btn @click="unsubscribe">
						 detener espera
					 </v-btn>
				 </v-col>
				 <v-col class="d-flex flex-column mt-2" v-else>
					 <v-btn @click="activateListener" :disabled="!!!gateway">
						 esperar respuesta dispositivo
					 </v-btn>
				 </v-col>
			 </v-row>
		 </v-card-actions>
		 <v-card-text>
			 <pre>{{measure}}</pre>
		 </v-card-text>
	 </v-card>
 </div>
</template>

<style scoped>

</style>