<template>
	<v-responsive class="base-divider mx-auto" :class="classes" max-width="40">
		<v-divider />
	</v-responsive>
</template>

<script>
export default {
	name: "divider",
	props: {
		color: {default: 'blue'},
		space: {type: [Number, String], default: 3,}
	},
	computed: {
		classes() {
			return [ this.color, `my-${this.space}` ]
		}
	}
}
</script>

<style lang="sass" scoped>
	.base-divider .v-divider
		border-width: 3px 0 0 0 !important

	.base-divider.base-divider--dense .v-divider
		border-width: 2px 0 0 0 !important
</style>