<template>
	<v-form v-model="cuestionarioForm" ref="cuestionarioForm">
		<v-row justify="center">
			<v-col cols="12" :sm="fullscreen?12:10">
				<v-overlay absolute :value="showFin" v-if="fullscreen">
					<v-alert type="success">No hay más preguntas</v-alert>
				</v-overlay>
				<v-overlay absolute :value="loadingPregunta">
					<v-progress-circular indeterminate size="64"/>
				</v-overlay>
				<v-card :flat="fullscreen" :class="{'pa-0': fullscreen}">
					<v-card-title v-if="has_emergency_evidence && !should_stop">
						<v-alert type="error">
							<div class="text-justify" style="word-break: break-word">Sus síntomas evidencian una emergencia médica, puede dar clic en "terminar" el cuestionario, sin embargo, recomendamos continuar hasta que terminen las preguntas para obtener la mayor cantidad de información y facilitar el proceso de diagnóstico.</div></v-alert>
					</v-card-title>
					<v-card-text class="pb-0">
						<component :is="question.type" :pregunta="question" :emergencia="has_emergency_evidence" :terminar="should_stop" v-if="question"/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import preguntasSingle from '@/components/preguntasSingle'
import preguntasMultipleSingle from '@/components/preguntasMultipleSingle'
import preguntasMultiple from '@/components/preguntasMultiple'
import {mapGetters} from 'vuex'
import * as types from '@/store/types'
import {fetchOptions, refresh_token} from "@/actions/actions";

export default {
	name: "cuestionario",
	components:{ single:preguntasSingle, group_single:preguntasMultipleSingle, group_multiple:preguntasMultiple  },
	props: { interview:{required: true} , fullscreen: {type: Boolean, default: false} },
	data:()=>({
		cuestionarioForm:false,
		question: null,
		should_stop: false,
		has_emergency_evidence:false,
		respuestas:[],
		estiloPregunta: null,
		loadingPregunta:false,
		showFin: false
	}),
	created(){
		this.fetchPregunta();
		Event.$on('respuesta', ()=>{ this.fetchPregunta(); })
	},
	computed:{
		...mapGetters({ evidence: types.EVIDENCE, api_url: types.API_URL})
	},
	watch:{
		should_stop: function(val){
			this.showFin = val;
		}
	},
	methods:{
		fetchPregunta(){
			this.loadingPregunta = true
			const data={
				mode: "diagnosis",
				evidence: this.evidence
			}

			fetch(`${this.api_url}interview/${this.interview}`, fetchOptions({method:"POST", auth, body: data}))
				.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })
				.then( response =>{
					this.$store.dispatch(types.INCREMENT_CALLS);
					this.should_stop = response.result.should_stop;
					this.question = response.result.question;
					this.has_emergency_evidence = response.result.has_emergency_evidence
					if(this.fullscreen) {
						Event.$emit('proRespuesta', response);
					}
					else{
						Event.$emit('updateDiagnostico', _.slice(response.result.conditions,0,4))
					}
					this.loadingPregunta = false

				})
				.catch(e=>{
					Promise.resolve(e).then(error=>{
						if(JSON.parse(error).message === 'Unauthorized'){ refresh_token(); Event.$emit('respuesta' ) }
					})
					this.loadingPregunta = false
				})
				.finally(()=>{ cargando(false); this.loadingPregunta = false})
		}
	}
}
</script>

<style>
 .pregunta{ word-break: break-word !important; text-align: justify!important; }
</style>
