<template>
	<v-container>
		<div class="text-h4 my-5">Lista de pacientes en espera</div>
		<v-data-table :headers="headers" :items="pacientesPriorizado" class="elevation-1" >
			<template v-slot:item.date="{ item }">
				{{ calcDate(item.date) }} 
			</template>
			<template v-slot:item.espera="{ item }">
				más de {{ calcEspera(item.date) }}
			</template>
			<template v-slot:item.nombre="{ item }">
				{{item.user.primerNombre + (item.user.segundoNombre?" "+item.user.segundoNombre:"") + " " + item.user.primerApellido+ (item.user.segundoApellido?" "+item.user.segundoApellido:"") }}
			</template>
			<template v-slot:item.triage_level="{ item }">
				<v-chip :color="item.triage ? getTriage[item.triage_level] : 'black'" dark>
					{{ item.triage ? item.triage_level  : 'N/D'}}
				</v-chip>
			</template>
			<template v-slot:item.sede="{ item }">
				{{ _.find(sedes,['id', parseInt(item.sede)])?.nombre || item.sede || "N/D" }}
			</template>

			<template v-slot:item.atendido="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{on}">

                        <v-btn v-on="on" icon color="blue" @click="mostrarResultados(item)">
                            <v-icon>fad fa-receipt</v-icon>
                        </v-btn>
					</template>
					Atender
				</v-tooltip>
			</template>


			<template v-slot:item.resultados="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{on}">
                        <v-btn v-on="on" icon color="green" @click="modificar(item.firebaseid, 'atendido')">
                            <v-icon>fad fa-user-check</v-icon>
                        </v-btn>
					</template>
					Resultados entrevista
				</v-tooltip>
			</template>



		</v-data-table>
		<v-dialog v-model="resultadosDialog" persistent max-width="50%">
			<v-card>
				<v-card-title>
					<v-row>
                        Síntomas reportados
						<v-spacer/>
                        <v-btn icon color="red" @click="resultadosDialog=false">
                            <v-icon>fad fa-times</v-icon>
                        </v-btn>
					</v-row>

				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="8">
							<ul>
                                <li v-for="ev in filteredEvidence" :key="ev.id">
                                    {{_.find(items,["id", ev.id])?.name||"N/D" }} {{ev.choice_id==="absent"?" - No":ev.choice_id==="unknown"?' - No sabe':ocultarNo?"":" - Si"}}
                                </li>
							</ul>
						</v-col>
						<v-col>
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn v-on="on" outlined small @click="ocultarNo=!ocultarNo">
                                        {{ocultarNo?'Mostrar todos':'Mostrar solo SI'}}
                                    </v-btn>
                                </template>
                                <span>{{`Mostrar ${ocultarNo?'TODAS respuestas':'solo respuestas SI'}`}}</span>
                            </v-tooltip>
						</v-col>
					</v-row>
					<v-row>
                        <div class="text-h6">Nivel triage:
                            <v-chip :color="resultados ? getTriage[resultados?.triage_level] : 'black'" dark>
                                {{ resultados?.triage_level || 'N/D'}}
                            </v-chip>
                        </div>
					</v-row>
					<v-row>
                        <v-form ref="finalizarForm" v-model="finalizarForm">
                            <div class="text-h6">Para finalizar la encuesta, por favor, escriba cual fue la sugerencia final que se le dio al paciente:</div>
	                        <v-textarea rows="3" class="d-block" counter solo dense v-model="sugerenciaFinal" :rules="[v=>v.length>20||'La recomendación final no puede ser mas corta a 30 caracteres']"/>
                        </v-form>
					</v-row>
				</v-card-text>
				<v-card-actions>
                    <v-btn @click="finalizar" outlined color="primary">Finalizar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import {CONCEPTOS, MUTATION_RESET_INTERVIEW, PACIENTES, PERMISOS, PRO, UBICACION} from "@/store/types";
import store from "@/store";
import {mapGetters} from "vuex";
import moment from "moment";
import {updateCollection} from "@/actions/actions";
import firebase from "firebase/app";
import { SEDES } from "../store/types";
import * as types from "@/store/types";

export default {
	name: "Teleorientacion",
	data:()=>({
		pacientes: [],
		pacientesPriorizado:[],
		headers: [
			{ text: 'Fecha encuesta',  value: 'date', width: 150, sortable: false },
			{ text: 'Tiempo de espera',  value: 'espera', width: 150, sortable: false },
			{ text: 'Nombre completo',  value: 'nombre', sortable: false },
			{ text: 'Identificación',  value: 'user.identificacion', sortable: false },
			{ text: 'Contacto',  value: 'user.telefono', sortable: false },
			{ text: 'Sede',  value: 'sede', sortable: false },
			{ text: 'Nivel Triage',  value: 'triage_level', sortable: false },
			{ text: 'Atender', align: 'center', value: 'atendido' ,width: 100, sortable: false },
		],
		getTriage: ["","red","deep-orange","amber","green","blue"],
		resultadosDialog: false,
		resultados:{},
		ocultarNo: true,
        finalizarForm:false,
		sugerenciaFinal: ""
	}),
	created(){
		moment.locale('es-mx')
		this.fetchPacientes();
		setInterval(this.fetchPacientes, 300000)
	},
	computed:{
		...mapGetters({permisos: PERMISOS, items: CONCEPTOS, sedes: SEDES}),
		filteredEvidence(){
			if(this.resultados?.evidence){
				this.resultados.evidence.splice(_.findIndex(this.resultados.evidence, ['id', 'p_14']),1)
				if(this.ocultarNo) {
					return this.resultados.evidence.filter( item=>{
						return item.choice_id!=='unknown'&&item.choice_id!=='absent'
					} )
				}
				return this.resultados?.evidence
			}
		},
	},
	watch:{
		pacientes: function(pacientes){
			this.pacientesPriorizado = this.sortPacientes(pacientes)
		}
	},
	methods: {
		sortPacientes(pacientes){
			return _.orderBy(pacientes, ['score','triage_level'], ['desc', 'asc']);
		},
		fetchPacientes(){
			db.collection('interviews')
				.where('date', '>', Date.now()-172800000 )
				.where('terminada','==', true )
				.where('fuente','==', 'paciente' )
				.where('ubicacion','==', 'remoto' )
				.where('atendido', '==',  false)
				.onSnapshot(snapshot => {
				this.pacientes = [];
				snapshot.forEach( doc => {
					let temp = doc.data();
					temp.firebaseid= doc.id;
					let score = 0
					switch (temp.triage_level){
						case 1: score += 15; break;
						case 2: score += 13; break;
						case 3: score += 6; break;
						case 4: score += 4; break;
						default: score += 3; break;
					}

					if(temp.user.nombre) {
						temp.user.primerNombre = temp.user.nombre
						delete temp.user.nombre
						temp.user.primerApellido = temp.user.apellidos
						delete temp.user.apellidos
						updateCollection('interviews', doc.id, {user: { ...temp.user, nombre: firebase.firestore.FieldValue.delete(), apellidos: firebase.firestore.FieldValue.delete() } })
					}

					if(temp.user.edad > 65) score += 1;
					if(temp.user.discapacidad) score += 1;
					if(temp.user.cancer) score += 1;

					const tiempoEspera =  Math.round((Date.now() - temp.date) / 1800000 );
					if(tiempoEspera > 1 ) score += tiempoEspera > 5 ? 5 : tiempoEspera

					temp.score = score;
					this.pacientes.push(temp);
				})
			})
		},
		calcDate(date) {
			return moment(date).format('DD MMMM YYYY - hh:mm a')
		},
		calcEspera(date) {
			return moment(date).fromNow(true)
		},
		modificar(id, item){
			swal.fire({
				title: `¿El paciente va a ser ${item}?`,
				showDenyButton: true,
				showCancelButton: true,
				confirmButtonText: 'Si',
				denyButtonText: `No`,
			}).then((result) => {
				if (result.isConfirmed) {
					updateCollection('interviews', id, {[item]: true})
					swal.fire(_.capitalize(item), '', 'success')
				} else if (result.isDenied) {
					swal.fire(`El paciente no ha sido ${item}`, '', 'info')
				}
			})

		},
		async triage(id){
			const { value: triage } = await swal.fire({
				title: 'Ingrese el nivel de triage',
				input: 'select',
				inputOptions: {
					1: '1 - Reanimación',
					2: '2 - Emergencia',
					3: '3 - Urgencia',
					4: '4 - Consulta Prioritaria',
					5: '5 - No urgente ',
					'Remitir': 'Remitir a otra entidad',
				},
				inputPlaceholder: 'Seleccione un nivel de triage',
				showCancelButton: true,
				cancelButtonText: 'Cancelar'
			})

			if (triage) {
				let data = {}

				if(! isNaN(parseInt(triage)) ) {
					data.triage_level = parseInt(triage);
					data.evaluacion_triage = true

					return await updateCollection('interviews', id, data)
				}
				else{
					return this.modificar(id, 'atendido')
				}
			}
		},
		mostrarResultados(item){
			this.resultadosDialog = true
			this.resultados = item
		},
        finalizar(){
            if(this.$refs.finalizarForm.validate()){
                updateCollection('interviews', this.resultados.firebaseid, {
                    sugerenciaTeleorientacion: this.sugerenciaFinal,
	                atendido: true,
                    orientadoPor: {id:this.$store.getters[types.USER_ID], name:this.$store.getters[types.USER]}
                }).then(()=>{
                    this.resultadosDialog = false
                })
            }
        },

	}
}
</script>

<style scoped>

</style>
