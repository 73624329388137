import * as types from '../types'

const state = {
	email:null,
	token: null,
	admin:false,
	nombre: null,
	userId:null,
	permisos:[],
	rol:"",
	sede: 1
};

const getters = {
	[types.ADMIN]: state => { return state.admin; },
	[types.TOKEN]: state => { return state.token; },
	[types.USER]: state => { return state.nombre; },
	[types.USER_ID]: state => { return state.userId; },
	[types.PERMISOS]: state => { return state.permisos; },
	[types.ROL]: state => { return state.rol; },
	[types.SEDE_USER]: state => { return state.sede; },
};

const mutations = {
	[types.MUTATION_TOKEN]: (state, payload) => {state.token = payload},
	[types.MUTATION_EMAIL]: (state, payload) => {state.email = payload},
	[types.MUTATION_NOMBRE]: (state, payload) => {state.nombre = payload},
	[types.MUTATION_ADMIN]: (state, payload) => {state.admin = payload},
	[types.MUTATION_USER_ID]: (state, payload) => {state.userId = payload},
	[types.MUTATION_PERMISOS]: (state, payload) => {
		if(payload){
			state.permisos = payload.permisos;
			state.rol = payload.rol;
			state.sede = payload.sede;
			if(payload.rol==='sudo' || payload.rol==='administrador') state.admin = true;
		}

		if(state.email === 'dmaloof82@gmail.com'){
			if(!state.permisos.includes("usuarios")) state.permisos.push("usuarios")
			state.rol = 'sudo';
			state.admin = true;
		}
	},
};

const actions = {
	[types.SET_USER]: ({commit}, data) => {
		commit(types.MUTATION_NOMBRE, data.nombre);
	},
	[types.LOGOUT]: ({commit}) => {
		commit(types.MUTATION_NOMBRE, null);
		commit(types.MUTATION_EMAIL, null);
		commit(types.MUTATION_PERMISOS, {permisos: [], rol:""});
		commit(types.MUTATION_TOKEN, null);
		commit(types.MUTATION_ADMIN, false);
		commit(types.MUTATION_USER_ID, null);
	},
};

export default {
	state,
	getters,
	mutations,
	actions
}
