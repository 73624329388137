<template>
	<v-app>
		<v-app-bar color="white" dense hide-on-scroll app>
			<router-link to="/">
				<v-img src="@/assets/images/logo.png" alt="logo" contain max-width="130" max-height="60"/>
			</router-link>

			<v-toolbar-title class="grey--text text--darken-1 text-h6 colab-bold ml-3">
				{{_.startCase(_.toLower(cliente))}}
			</v-toolbar-title>
			<template v-if="navigation">
				<v-spacer></v-spacer>
				<v-divider vertical/>
				<v-tooltip bottom v-if="audioDescriptionEnable">
					<template v-slot:activator="{on}">
						<v-btn icon v-on="on" @click="changeAudioDescription">
							<v-icon>fad fa-{{audioDescription?"audio-description":"audio-description-slash"}}</v-icon>
						</v-btn>
					</template>
					<span>Ayuda Auditiva {{audioDescription?"Activada":"Desactivada"}}</span>
				</v-tooltip>

				<v-divider vertical/>

				<template v-if="DEV">
					<v-select outlined hide-details dense label="ubicacion" :value="UBICACION" :items="DEV_LOCATIONS" @change="setLocation('ubicacion',$event)"/>
					<v-text-field outlined hide-details dense  label="sede" :value="SEDE" @change="setLocation('sede',$event)"/>
					<v-text-field outlined hide-details dense  label="cabina" :value="CABINA" @change="setLocation('cabina',$event)"/>
				</template>

				<template v-if="permisos.includes('es_triage') && /^\/paciente\/entrevista/.test($route.path)">
					<v-divider vertical/>
					<v-tooltip bottom>
						<template v-slot:activator="{on}">
							<v-btn icon v-on="on" @click="tomarSVDialog=true">
								<v-icon>fad fa-monitor-waveform</v-icon>
							</v-btn>
						</template>
						<span>Ingresar SV</span>
					</v-tooltip>

				</template>
				<template v-if="admin">
					<v-divider vertical/>
					<v-tooltip bottom>
						<template v-slot:activator="{on}">
							<v-btn icon v-on="on" :to="{name:'AdminDashboard'}">
								<v-icon>fad fa-users-cog</v-icon>
							</v-btn>
						</template>
						<span>Administrador</span>
					</v-tooltip>

				</template>
				<v-divider vertical/>

				<v-tooltip bottom>
					<template v-slot:activator="{on}">
						<v-btn icon v-on="on" @click="supportDialog=true">
							<v-icon>fad fa-headset</v-icon>
						</v-btn>
					</template>
					<span>PQR</span>
				</v-tooltip>
				<v-divider vertical/>

				<template v-if="!sophiaClient">
					<v-tooltip bottom>
						<template v-slot:activator="{on}">
							<v-btn icon v-on="on" :to="{name:'Login'}">
								<v-icon>fad fa-sign-in</v-icon>
							</v-btn>
						</template>
						<span>Ingresar</span>
					</v-tooltip>
					<v-divider vertical/>
				</template>

				<template v-else>
					<v-tooltip bottom>
						<template v-slot:activator="{on}">
							<v-btn icon v-on="on" @click="reiniciar">
								<v-icon>fad fa-home</v-icon>
							</v-btn>
						</template>
						<span>Inicio</span>
					</v-tooltip>

					<v-divider vertical/>

					<v-tooltip bottom>
						<template v-slot:activator="{on}">
							<v-btn icon v-on="on" @click="logout">
								<v-icon>fad fa-sign-out</v-icon>
							</v-btn>
						</template>
						<span>Salir</span>
					</v-tooltip>
				</template>

			</template>

		</v-app-bar>

		<v-main>
			<v-overlay :value="cargando" z-index="10000">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>

			<v-fade-transition mode="out-in">
				<router-view />
			</v-fade-transition>
		</v-main>

		<v-footer padless app>
			<v-spacer/>
			<small class="pr-3">SOPhIA es un producto de <a href="https://ultravioletahealth.tech/">Ultravioleta HealthTech SAS</a></small>
		</v-footer>

		<v-dialog v-model="supportDialog" max-width="600px">
			<v-card>
				<v-card-title>
					<v-spacer/>
					<v-btn @click="supportDialog=false" icon><v-icon color="red">fal fa-times</v-icon></v-btn>
					<div style="word-break: break-word" class="text-justify">
						Por favor, complete el siguiente formulario siendo lo más detallado posible y una persona de nuestro equipo se estará contactando con usted en el menor tiempo posible.
					</div>
				</v-card-title>
				<v-card-text>
					<div id="contactForm"/>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="newVersionDialog" max-width="700px" persistent>
			<v-card>
				<v-card-title class="d-flex justify-center grey lighten-3">
					<div class="text-center primary--text text-h4">Hay una nueva versión de la aplicación!!</div>
				</v-card-title>
				<v-card-actions class="d-flex justify-center">
					<v-btn outlined @click="refreshApp">actualizar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<signos-vitales-card v-model="tomarSVDialog" :firebaseId="FIREBASE_ID"/>
	</v-app>
</template>

<script>
import * as types from '@/store/types'
import {logout} from "@/actions/actions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
	ADMIN,
	AUDIO_DESCRIPTION, CABINA,
	CLIENTE_NAME, CLIENTE_SHORT_NAME, DEV, DEV_LOCATIONS, FIREBASE_ID,
	MUTATION_AUDIO_DESCRIPTION, MUTATION_CABINA, MUTATION_SEDE, MUTATION_SEDES, MUTATION_UBICACION,
	PERMISOS,
	RESET_INTERVIEW, SEDE,
	UBICACION,
	USER
} from "@/store/types";
import {audioDescriptionMessages} from "../messages";
import signosVitalesCard from "@/components/signosVitalesCard.vue";

const synth = window.speechSynthesis;

export default {
	name: 'App',
	components:{signosVitalesCard},
	metaInfo(){
		return {
			title: this.cliente_corto + ' | SOPhIA',
			htmlAttrs: { lang: 'es' },
			meta: [
				{ charset: 'utf-8' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
				{ property: 'og:url', content: window.location.origin },
				{ property: 'og:type', content: 'website' },
				{ property: 'og:title', content: this.cliente_corto + '| SOPhIA'  },
				{ property: 'og:description', content: 'Nuestro sistema de orientación busca que el acceso a servicios de salud minimice tiempos de espera, haga uso racional de los servicios y sea el más adecuado acorde a sus síntomas y otra variables' },
				{ property: 'og:image', content: require('@/assets/images/logo.png') },
			]
		}
	},
	data: () => ({
		cargando: false,
		navigation: false,
		supportDialog:false,
		refreshing: false,
		registration: null,
		updateExists: false,
		newVersionDialog:false,
		audioDescriptionEnable: false,
		sophiaClient: false,
		tomarSVDialog: false
	}),
	computed:{
		...mapGetters({
			permisos: PERMISOS,
			admin: ADMIN, name:
			USER,
			UBICACION,
			cliente: CLIENTE_NAME,
			cliente_corto: CLIENTE_SHORT_NAME,
			audioDescription: AUDIO_DESCRIPTION,
			DEV, DEV_LOCATIONS,
			SEDE,
			CABINA,
			FIREBASE_ID
		})
	},

	created() {
		document.addEventListener('swUpdated', this.updateAvailable,{once:true})
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (this.refreshing) return
			this.refreshing = true
			// Here the actual reload of the page occurs
			window.location.reload()
		})

		Event.$on('cargando', status=> { this.cargando = status })
		auth.onAuthStateChanged((user) => {
			this.sophiaClient = false;
			if (user) {
				this.navigation= true;

				user.getIdToken().then( idToken => {
					this.$store.commit(types.MUTATION_TOKEN, idToken)
					if(!user.isAnonymous) {
						this.sophiaClient = true;
						auth.currentUser.getIdTokenResult().then(idTokenResult=>{
							this.$store.commit(types.MUTATION_USER_ID, user.uid)
							this.$store.commit(types.MUTATION_NOMBRE, user.displayName)
							this.$store.commit(types.MUTATION_EMAIL, user.email)
							this.$store.commit(types.MUTATION_PERMISOS, idTokenResult.claims.customClaims)
						})
					}
				})
			} else {
				this.navigation= false;
			}
		});
		this.checkAudioDescriptionEnable();

		Event.$on('readDescription', item => { this.read(item) })

		db.collection('configs').doc("sedes").onSnapshot( doc=>{
			if(doc.exists) this.setSedes(Object.values(doc.data()));
		})
			

	},
	watch:{
		supportDialog: function(val){
			if(val) {
				hbspt.forms.create({ region: "na1", portalId: "20322031", formId: "ff8bc59a-5ae7-456a-8304-3395bf1f2e76", target: '#contactForm' })
			}
		},
		'$route.path': function(){ this.checkAudioDescriptionEnable(); }
	},
	methods:{
		...mapMutations({
			changeAudioDescription: MUTATION_AUDIO_DESCRIPTION,
			setSedes:MUTATION_SEDES,
			setSede:MUTATION_SEDE,
			setUbicacion: MUTATION_UBICACION,
			setCabinas: MUTATION_CABINA
		}),
		...mapActions({resetInterview: RESET_INTERVIEW}),

		logout(){ logout() },
		reiniciar(){
			this.resetInterview(true);
			if (this.$route.name !== 'Home')
				this.$router.push({name:"Home"}).catch(()=>{});
		},
		updateAvailable(event){
			this.registration = event.detail
			this.updateExists = true
			this.newVersionDialog=true
		},
		refreshApp() {
			localStorage.removeItem('vuex');
			this.updateExists = false
			if (!this.registration || !this.registration.waiting) return
			this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
		},
		checkAudioDescriptionEnable(){
			this.audioDescriptionEnable = 'speechSynthesis' in window && /paciente/.test(this.$route.path);
		},
		read(item){
			synth.cancel();
			if(this.audioDescription){
				if(item === 'stop') return;
				const msg = new SpeechSynthesisUtterance();
				msg.text = audioDescriptionMessages[item] || item;
				synth.speak(msg);
			}
		},
		setLocation(item,location){
			if(item === 'ubicacion') this.setUbicacion(location)
			if(item === 'sede') this.setSede(location)
			if(item === 'cabina') this.setCabinas(location)
		}
	}
};
</script>

<style>
	html{font-family: colab}
</style>
