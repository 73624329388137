import Vue from 'vue'
import Vuex from 'vuex'
import config from "@/store/modules/config";
import user from "@/store/modules/user";
import interview from "@/store/modules/interview";
import pro from "@/store/modules/pro";
import custom from "@/store/modules/custom";
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
	state:{},
	mutations:{},
	getters:{},
	actions:{},
	plugins: [createPersistedState(
		{
			paths: ['user',	'pro', 'custom']
		}
	)],
	modules: {
		config,
		user,
		interview,
		pro,
		custom
	},
})
