<template>
	<v-card>
		<v-card-text class="pt-5">
			<v-form ref="quemaduraForm">
				<div class="text-h5 primary--text mt-5">Origen</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'origin'})" :key="`origin_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>
				<div class="text-h5 primary--text mt-5">Gravedad</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'severity'})" :key="`severity_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>

				<div class="text-h5 primary--text mt-5">Ubicación</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'location'})" :key="`location_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>
			</v-form>

		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn outlined color="primary" @click="enviar()">enviar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "embarazo",
	data:()=>({
		concepts:{
			s_1943: {text: 'Lesión Solar', value: false, category: 'origin'},
			s_2214: {text: 'Química', value: false, category: 'origin'},
			s_1989: {text: 'Eléctrica', value: false, category: 'origin'},


			s_2039: {text: 'Quemadura de tercer grado', value: false, category: 'severity'},

			s_398: {text: 'Todo el Cuerpo', value: false, category: 'location'},
			s_2382: {text: 'Parte del Cuerpo', value: false, category: 'location'},
			s_1808: {text: 'Cara', value: false, category: 'location'},
			s_694: {text: 'Boca', value: false, category: 'location'},
			s_2060: {text: 'Pecho, estomago, espalda', value: false, category: 'location'},
			s_2266: {text: 'Brazos', value: false, category: 'location'},
			s_1982: {text: 'Manos', value: false, category: 'location'},
			s_2267: {text: 'Piernas', value: false, category: 'location'},
			s_1923: {text: 'Pies', value: false, category: 'location'},
			s_1810: {text: 'Zona Genital', value: false, category: 'location'},
		}
	}),
	methods: {
		enviar(){
			if(this.$refs.quemaduraForm.validate()){
				let evidence = [{"id": "p_234", "source": "initial", "choice_id": "present"}]
				_.forIn(this.concepts, function(values, key) {
					evidence.push({"id": key, "source": "initial", "choice_id": values.value?"present":"absent"})
				});

				this.$emit('input', evidence)
			}
		}
	}
}
</script>

<style scoped>

</style>
