import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard';

import Login from '@/views/auth/Login'
import ProVersion from '@/views/ProVersion.vue'
import Disclaimer from '@/views/paciente/Disclaimer.vue'
import DatosPaciente from '@/views/paciente/DatosPaciente.vue'
import Resultados from '@/views/custom/paciente/Resultados.vue'
import Home from '@/views/Home.vue'
import Start from '@/views/paciente/Start'
import Interview from '@/views/paciente/Interview'
import ListaEspera from '@/views/ListaEspera'
import Teleorientacion from '@/views/Teleorientacion.vue'
import template from "@/views/layouts/template";
import SignosVitales from "@/views/paciente/SignosVitales";
import testGlya from "@/views/testGlya.vue";


import './custom'

import * as _ from 'lodash'

import {
	ADMIN,
	CONCEPTOS,
	EXPIRES,
	SIGNOSVITALES,
	EXPIRESSIGNOSVITALES,
	SET_CONCEPTOS,
	SET_SIGNOSVITALES,
	MUTATION_EXPRESS,
	MUTATION_ESI,
	MUTATION_PACIENTES,
	MUTATION_PRO,
	PACIENTES,
	PRO,
	PERMISOS,
	DISCLAIMER,
	MUTATION_UBICACION,
	MUTATION_SEDE, MUTATION_CABINA
} from "@/store/types";

import store from "@/store";
import moment from "moment";
import {updateCollection} from "@/actions/actions";
import firebase from "firebase/app";
import CustomRoutes from "@/router/custom";

const pacientes = store.getters[PACIENTES]
const pro = store.getters[PRO]
const permisos = store.getters[PERMISOS]

Vue.use(VueRouter)

// const host = window.location.host;
// const parts = host.split('.');
// console.log(parts)


/**
 *
 * CONFIG DATA FETCH
 *
 */

const fetchConceptos = function(to, from, next) {
	if(!store.getters[CONCEPTOS] || !(!!store.getters[EXPIRES])|| moment(store.getters[EXPIRES]).diff(moment()) > 30){
		cargando(true);
		store.dispatch(SET_CONCEPTOS).finally(()=> {
			cargando(false);
		});
	}
	next();
}


const fetchSignosVitales = function(to,from,next){
	if(!store.getters[SIGNOSVITALES] || !(!!store.getters[EXPIRESSIGNOSVITALES]) || moment(store.getters[EXPIRESSIGNOSVITALES]).diff(moment()) > 1 ) {
		cargando(true);
		store.dispatch(SET_SIGNOSVITALES).finally(()=> {
			cargando(false);
		} );
	}
	next();
}

const configs = function(to, from, next) {
	db.collection('configs').doc('configs').onSnapshot(
		doc=>{
			if(!doc.exists){
				updateCollection("configs", "configs", {
					express: false, esi: true, pacientes: true, pro: true
				})
			}
			store.commit(MUTATION_EXPRESS, doc.data()?.express || false)
			store.commit(MUTATION_ESI, doc.data()?.esi)
			store.commit(MUTATION_PACIENTES, doc.data()?.pacientes)
			store.commit(MUTATION_PRO, doc.data()?.pro)
		}
	)
	next()
}
/**
 *
 * MIDDLEWARE ACCESOS A PAGINAS
 *
 */

const isAdmin = function(to, from, next) {
	if(store.getters[ADMIN]){ next() }
	else { next({ name: 'Login' })}
};

const userLogged = async (to, from, next)=>{
	if(auth.currentUser && !auth.currentUser.isAnonymous){ return next() }
	if(auth?.currentUser?.isAnonymous) await auth.signOut();
	return next({ name: 'Login' });
}

const clearAnonymousUser = async(to, from, next)=>{
	if(auth.currentUser?.isAnonymous) {
		await firebase.auth().signOut()
	}
	await auth.signInAnonymously();
	return next();
}

const pacientePermission = async(to, from, next)=>{
	if(to.name ==='DisclaimerPaciente') return next();
	if(store.getters[DISCLAIMER]) return next();
	return next({name: 'DisclaimerPaciente'});
}

const proPermission =async (to, from, next)=>{
	if(permisos.includes("evaluar") && pro) return next();
	return next({name:"Home"});
}

const ListaEsperaPermision = (to, from, next)=>{
	if(permisos.includes("espera")) return next();
	return next({name:"Home"});
}

const ListaCallCenterPermision = (to, from, next)=>{
	if(permisos.includes("teleorientacion")) return next();
	return next({name:"Home"});
}

/**
 *
 * REDIRECTS
 *
 */

const homeRedirect = (to, from, next)=>{
	if(pro && !pacientes) return  next({name: "ProVersion"});
	if(!pro && pacientes) return  next({name: "DisclaimerPaciente"});
	return next()
}

const prepaciente = (to, from, next)=>{
	const {u,s,c,t} = to.query

	if(t && ( moment([2023, 1, 28]).diff(moment().startOf('day'),'days') )> 0 ) store.commit(MUTATION_UBICACION, 'sala')
	if(u) store.commit(MUTATION_UBICACION, u)
	if(s) store.commit(MUTATION_SEDE, s)
	if(c) store.commit(MUTATION_CABINA, c)

	return next({name: "DisclaimerPaciente"})
}


const AppRoutes = [
	{ path: '/login', name: 'Login', component: Login },
	{ path: '/', redirect: { name: 'DisclaimerPaciente' } },
	{ path: '/home', name: 'Home', component: Home, beforeEnter: multiguard([userLogged,configs, homeRedirect]) },
	{ path: '/lista/', name: 'ListaEspera', component: ListaEspera, beforeEnter: multiguard([userLogged,ListaEsperaPermision, fetchConceptos]) },
	{ path: '/teleorientacion/', name: 'Teleorientacion', component: Teleorientacion, beforeEnter: multiguard([userLogged,ListaCallCenterPermision, fetchConceptos]) },

	{ path: '/testGlya/', name: 'testGlya', component: testGlya, beforeEnter: multiguard([userLogged]) },

	/**
	 * PRO
	 */

	{ path: '/pro/', name: 'ProVersion', component: ProVersion, beforeEnter: multiguard([userLogged,proPermission, fetchConceptos,fetchSignosVitales,configs])},

	/**
	 * PACIENTE
	 */
	{ path: '/prepaciente', name: 'Prepaciente', beforeEnter: multiguard([prepaciente])},

	{ path: '/paciente', component: template, children: [
			{ path: 'datos', name: 'DatosPaciente', component: DatosPaciente, beforeEnter: multiguard([pacientePermission]) },
			{ path: 'iniciar', name: 'Start', component: Start, beforeEnter: multiguard([pacientePermission]) },
			{ path: 'resultado', name: 'Resultados', component: Resultados, beforeEnter: multiguard([pacientePermission]) },
			{ path: 'entrevista/:interview', name: 'Interview', component: Interview, props: true, beforeEnter: multiguard([pacientePermission])},
			{ path: '/', name: 'DisclaimerPaciente', component: Disclaimer, beforeEnter: multiguard([clearAnonymousUser, pacientePermission]) },
			{ path: '/signosvitales', name: 'SignosVitales', component: SignosVitales, beforeEnter: multiguard([pacientePermission]) },
		]},

	/**
	 * ADMIN
	 */
	{ path: '/admin', component: () => import(/* webpackChunkName: "users" */ '../views/layouts/admin'), children: [
			{ path: '', name: 'AdminDashboard', component: () => import(/* webpackChunkName: "AdminDashboard" */ '../views/admin/AdminDashboard'), beforeEnter: multiguard([userLogged, isAdmin, configs]) },
			{ path: 'users', name: 'Users', component: () => import(/* webpackChunkName: "users" */ '../views/admin/Users'), beforeEnter:multiguard([userLogged, isAdmin]) },
			{ path: 'user/:id', name: 'User', component: () => import(/* webpackChunkName: "user" */ '../views/admin/User'), beforeEnter:multiguard([userLogged, isAdmin]) },
			{ path: 'consumos', name: 'Consumos', component: () => import(/* webpackChunkName: "consumos" */ '../views/admin/Consumos'), beforeEnter:multiguard([userLogged, isAdmin]) },
			{ path: 'sedes', name: 'Sedes', component: () => import(/* webpackChunkName: "consumos" */ '../views/admin/Sedes'), beforeEnter:multiguard([userLogged, isAdmin]) },,
			{ path: 'glyaGatewaysConfig', name: 'GlyaGatewaysConfig', component: () => import(/* webpackChunkName: "consumos" */ '../views/admin/glyaGateways'), beforeEnter:multiguard([userLogged, isAdmin]) },
		]},
]

const routes =	[...AppRoutes, ...CustomRoutes]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach(async(to, from, next) =>  {
	if(from.name === to.name) return;
	next();
})

export default router
