/*
 Control de nombres de variables de store

 export const NOMBRE = 'string';

 */

//Getters
export const DEV = 'configs/DEV';
export const DEV_LOCATIONS = 'configs/DEV_LOCATIONS';
export const CLIENTE_NAME = 'configs/CLIENTE_NAME';
export const CLIENTE_SHORT_NAME = 'configs/CLIENTE_SHORT_NAME';
export const AUDIO_DESCRIPTION = 'configs/AUDIO_DESCRIPTION';
export const APP_URL = 'configs/APP_URL';
export const API_URL = 'configs/API_URL';
export const PACIENTES = 'configs/PACIENTES';
export const RUTA_PACIENTES = 'configs/RUTA_PACIENTES';
export const PRO = 'configs/PRO';

export const ADMIN = 'user/ADMIN';
export const TOKEN = 'user/TOKEN';
export const USER = 'user/USER';
export const EMAIL = 'user/EMAIL';
export const USER_ID = 'user/USER_ID';
export const PERMISOS = 'user/PERMISOS';
export const ROL = 'user/ROL';
export const SEDE_USER = 'user/SEDE_USER';

export const INTERVIEW = 'interview/INTERVIEW';
export const FIREBASE_ID = 'interview/FIREBASE_ID';
export const EVIDENCE = 'interview/EVIDENCE';
export const INTERVIEW_USER = 'interview/INTERVIEW_USER';
export const INTERVIEW_USER_ID = 'interview/INTERVIEW_USER_ID';
export const AGE = 'interview/AGE';
export const SEX = 'interview/SEX';
export const CALLS = 'interview/CALLS';
export const RESULTADOS = 'interview/RESULTADOS';


export const CONCEPTOS = 'pro/CONCEPTOS';
export const SIGNOSVITALES = 'pro/SIGNOSVITALES';
export const EXPIRES = 'pro/EXPIRES';
export const EXPIRESSIGNOSVITALES = 'pro/EXPIRESSIGNOSVITALES';
export const EXPRESS = 'pro/EXPRESS';
export const ESI = 'pro/ESI';
export const MENSAJES = 'pro/MENSAJES';


//Mutations
export const MUTATION_AUDIO_DESCRIPTION = 'configs/MUTATION_ADMIN'
export const MUTATION_PACIENTES = 'configs/MUTATION_PACIENTES';
export const MUTATION_RUTA_PACIENTES = 'configs/MUTATION_RUTA_PACIENTES';
export const MUTATION_PRO = 'configs/MUTATION_PRO';

export const MUTATION_ADMIN = 'user/MUTATION_ADMIN'
export const MUTATION_TOKEN = 'user/MUTATION_TOKEN'
export const MUTATION_EMAIL = 'user/MUTATION_EMAIL'
export const MUTATION_NOMBRE = 'user/MUTATION_NOMBRE'
export const MUTATION_SEX = 'user/MUTATION_SEX'
export const MUTATION_AGE = 'user/MUTATION_AGE'
export const MUTATION_USER_ID = 'user/MUTATION_USER_ID';
export const MUTATION_PERMISOS = 'user/MUTATION_PERMISOS';
export const MUTATION_CALLS = 'user/MUTATION_CALLS';

export const MUTATION_INTERVIEW = 'interview/MUTATION_INTERVIEW'
export const MUTATION_INTERVIEW_USER = 'interview/MUTATION_INTERVIEW_USER'
export const MUTATION_INTERVIEW_USER_ID = 'interview/MUTATION_INTERVIEW_USER_ID'
export const MUTATION_EVIDENCE = 'interview/MUTATION_EVIDENCE'
export const MUTATION_FIREBASE_ID = 'interview/MUTATION_FIREBASE_ID';
export const MUTATION_RESET_INTERVIEW = 'interview/MUTATION_RESET_INTERVIEW';
export const MUTATION_RESULTADOS = 'interview/MUTATION_RESULTADOS';

export const MUTATION_CONCEPTOS = 'pro/MUTATION_CONCEPTOS';
export const MUTATION_SIGNOSVITALES = 'pro/MUTATION_SIGNOSVITALES';
export const MUTATION_EXPIRES = 'pro/MUTATION_EXPIRES';
export const MUTATION_EXPIRESSIGNOSVITALES = 'pro/MUTATION_EXPIRESSIGNOSVITALES';
export const MUTATION_EXPRESS = 'pro/MUTATION_EXPRESS';
export const MUTATION_ESI = 'pro/MUTATION_ESI';

//Actions
export const SET_USER = 'user/SET_USER'
export const LOGOUT = 'user/LOGOUT'
export const START_INTERVIEW = 'interview/START_INTERVIEW'
export const ADD_EVIDENCE = 'interview/ADD_EVIDENCE'
export const ADD_MULTIPLE_EVIDENCE = 'interview/ADD_MULTIPLE_EVIDENCE'
export const SET_EVIDENCE = 'interview/SET_EVIDENCE'
export const REMOVE_EVIDENCE = 'interview/REMOVE_EVIDENCE'
export const CLEAN_ALL_EVIDENCE = 'interview/CLEAN_ALL_EVIDENCE'
export const INCREMENT_CALLS = 'interview/INCREMENT_CALLS'
export const SET_CONCEPTOS = 'pro/SET_CONCEPTOS'
export const SET_SIGNOSVITALES = 'pro/SET_SIGNOSVITALES'
export const RESET_INTERVIEW = 'interview/RESET_INTERVIEW'
export const DELETE_INTERVIEW = 'interview/DELETE_INTERVIEW'
export const SET_RESULTADOS = 'interview/SET_RESULTADOS'

//CUSTOMS
export const UBICACION = 'custom/UBICACION'
export const SEDE = 'custom/SEDE'
export const SEDES = 'custom/SEDES'
export const CABINA = 'custom/CABINA'
export const MUTATION_UBICACION = 'custom/MUTATION_UBICACION'
export const MUTATION_SEDE = 'custom/MUTATION_SEDE'
export const MUTATION_SEDES = 'custom/MUTATION_SEDES'
export const MUTATION_CABINA = 'custom/MUTATION_CABINA'
export const DISCLAIMER = 'custom/DISCLAIMER'
export const MUTATION_DISCLAIMER = 'custom/MUTATION_DISCLAIMER'

