<template>
	<v-container>
		<v-card>
			<v-card-title>
				<div class="text-h4 primary--text">Toma de signos vitales</div>

			</v-card-title>
			<v-card-subtitle>
				<div>ID Cabina: {{cabina}}</div>
			</v-card-subtitle>
			<v-card-text>
				<div class="text-h6">
					Por favor, presiona el botón para iniciar la toma de signos vitales.
				</div>
				<v-row>
					<v-col class="d-flex flex-column mt-5" v-if="esperandoRespuesta">
						<div class="mx-auto">Esperando resultados de los signos vitales</div>
						<v-progress-circular  class="mx-auto"
							indeterminate
							color="primary"
						/>
					</v-col>
					<v-col class="d-flex justify-center mt-5" v-else>
						<v-btn class="" outlined @click="iniciar">iniciar toma de signos vitales</v-btn>
						<v-btn class="ml-2" outlined @click="svDialog=true">Ingreso manual de SV</v-btn>
					</v-col>
				</v-row>
			</v-card-text>

		</v-card>

		<signos-vitales-card :firebaseId="firebaseId" v-model="svDialog"/>

	</v-container>
</template>

<script>
import configs from '../../../configs.json'
import {mapActions, mapGetters} from "vuex";
import {ADD_EVIDENCE, CABINA, FIREBASE_ID, REMOVE_EVIDENCE, SIGNOSVITALES} from "@/store/types";
import axios from "axios";
import {updateCollection} from "@/actions/actions";
import signosVitalesCard from "../../components/signosVitalesCard.vue"
export default {
	name: "SignosVitales",
	components:{ signosVitalesCard },
	data:()=>({
		cabinas: configs.cabinas,
		interviewData: null,
		esperandoRespuesta: false,
		params: {
			interview: null,
			riskFactors: null
		},
		svDialog: false,

	}),
	computed:{
		...mapGetters({
			cabina: CABINA,
			firebaseId: FIREBASE_ID
		})
	},
	created: async function() {
		this.params = {
			interview: this.$route.params.interview,
			riskFactors: this.$route.params.riskFactors
		}

		db.collection('signosVitales').doc(this.cabina).onSnapshot( doc =>{
			console.log(doc)
			// this.interviewData = doc.data()
		})
	},
	watch: {
		interviewData: function(val){
			console.log(val)
			// if( val.signosVitales ) {
			// 	this.$router.push({
			// 		name: 'Interview',
			// 		params: this.params
			// 	})
			// }
		}
	},
	methods:{
		iniciar(){
			this.esperandoRespuesta = true;
			// TELSIT
			// axios.post(`https://${this.cabinas[this.cabina]}/`, {"start": true, interview_id: this.firebaseId});

			db.collection('signosVitales').doc(this.cabina).onSnapshot( doc =>{
				this.interviewData = doc.data()
			})



		}
	}


}
</script>

<style scoped>

</style>
