import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';
import config from "../../configs.json"

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es',
    },
    icons: {
        iconfont: 'fa',
    },
    theme: {
        "themes": config.themes
    },
});
