<template>
	<v-card>
		<v-card-text class="pt-5">
			<v-form ref="embarazoForm">
				<div class="text-h5 primary--text mt-5">Trimestre</div>
				<v-radio-group v-model="trimestre" row required :rules="[v=>!!v||'El trimestre el obligatorio']">
					<v-radio label="I Trimestre" value="p_152"/>
					<v-radio label="II Trimestre" value="p_153"/>
					<v-radio label="III Trimestre" value="p_154"/>
				</v-radio-group>

				<div class="text-h5 primary--text mt-5">Síntomas</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'symptom'})" :key="`symptom_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>
				<v-row v-if="showIntensidad">
					<v-col cols="4">
						<div class="subtitle-1 secondary--text">Seleccione el nivel del dolor abdominal</div>
						<v-slider hint="Seleccione la intensidad del dolor abdominal" max="2" min="0"
								  :tick-labels="['Leve','Moderado','Grave']"
								  v-model="intesidad">
						</v-slider>
					</v-col>
				</v-row>
				<div class="text-h5 primary--text mt-5">Factores de Riesgo</div>
				<v-row>
					<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'risk_factor'})" :key="`risk_factor_${n}`">
						<v-switch :label="concept.text" v-model="concept.value" hide-details/>
					</v-col>
				</v-row>
			</v-form>

		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn outlined color="primary" @click="enviar()">enviar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "embarazo",
	data:()=>({
		trimestre:null,
		concepts: {
			s_13: {text: 'Dolor Abdominal', value: false, category: 'symptom'},
			s_21: {text: 'Dolor de Cabeza', value: false, category: 'symptom'},
			s_2008: {text: 'Contracciones', value: false, category: 'symptom'},
			s_39: {text: 'Dolor Orinar', value: false, category: 'symptom'},
			s_320: {text: 'Visión Alterada', value: false, category: 'symptom'},
			s_700: {text: 'Hinchazón', value: false, category: 'symptom'},
			s_1217: {text: 'Sangrado', value: false, category: 'symptom'},

			p_9: {text: 'Hipertensión', value: false, category: 'risk_factor'},
			c_532: {text: 'Diabetes Gestacional', value: false, category: 'risk_factor'},
			p_204: {text: 'Riesgo elevado de embarazo ectópico', value: false, category: 'risk_factor'},
		},
		intesidad: 0,
		painId:['s_1782','s_1783','s_1195'],
	}),
	computed:{
		showIntensidad() {
			if(this.concepts) return this.concepts['s_13'].value;
			else return false;
		}
	},
	methods: {
		enviar(){
			if(this.$refs.embarazoForm.validate()){
				let evidence = [
					{"id": 'p_42', "source": "initial", "choice_id": "present"},
					{"id": this.trimestre, "source": "initial", "choice_id": "present"}
				]
				_.forIn(this.concepts, function(values, key) {
					evidence.push({"id": key, "source": "initial", "choice_id": values.value?"present":"absent"})
				});
				if(this.showIntensidad) {
					evidence.push({"id": this.painId[this.intesidad], "source": "initial", "choice_id": "present"})
				}

				this.$emit("input", evidence)
			}

		}
	}
}
</script>

<style scoped>

</style>
