<template>
	<v-card flat>
		<v-card-title class="secondary--text pregunta">
			<h2>{{pregunta.text}}</h2>
		</v-card-title>
		<v-card-text>
			<v-form id="pregMulSingForm" ref="pregMulSingForm">
				<v-radio-group v-model="respuesta" :rules="rules.required">
					<v-radio v-for="(item, n) in pregunta.items" :key="n" :label="item.name" :value="item.id"/>
				</v-radio-group>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn outlined color="primary" :disabled="!respuesta" @click="siguiente" v-if="!terminar">Siguiente</v-btn>
			<v-btn v-if="terminar||emergencia" outlined color="accent" @click="enviar">Terminar</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import * as types from '@/store/types'
import validationRules from "@/actions/validationRules";
import {ADD_EVIDENCE, ADD_MULTIPLE_EVIDENCE} from "@/store/types";

export default {
	name: "preguntasMultipleSingle",
	props:{ pregunta: {required: true}, terminar: {required:true}, emergencia:{required:true}  },
	data: ()=>({
		respuesta: null,
		pregMulSingForm:false,
		rules: validationRules
	}),
	computed:{
		...mapGetters({ evidence: types.EVIDENCE})
	},
	methods:{
		...mapActions({addEvidence: ADD_EVIDENCE, addMultipleEvidence:ADD_MULTIPLE_EVIDENCE}),
		siguiente(){
			if(this.$refs.pregMulSingForm.validate()){
				cargando(true);
				let dataSet=[];

				this.pregunta.items.forEach(item =>{
					dataSet.push({"id": item.id, "choice_id": item.id === this.respuesta?"present":"absent"})
				})

				this.addMultipleEvidence(dataSet).then(()=>{
					Event.$emit('respuesta', dataSet);
					this.respuesta = null;
					this.$refs.pregMulSingForm.reset();
				})
				cargando(false);
			}
		},
		enviar(){
			if(this.$refs.pregMulSingForm.validate()) {
				cargando(true);
				this.addEvidence({"id": this.respuesta, "choice_id": "present"}).then(() => {
					Event.$emit('resultado');
				})
				cargando(false);
			}
		}
	}
}
</script>

<style scoped>

</style>
