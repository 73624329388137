<template>
	<v-container>
		<v-row justify="center">
			<img :src="require('@/assets/images/logo.png')" style="height: 30%; max-height: 250px"/>
		</v-row>
		<div class="text-h5 mt-5 primary--text font-weight-bold">Bienvenido a la plataforma de evaluación de SOPh<span class="secondary--text">IA</span></div>

		<v-row>
			<v-col cols="12">Por favor seleccione una de las opciones para iniciar.</v-col>
		</v-row>

		<v-row justify="center" align="center" class="mt-5">
			<v-col v-for="(item,n) in items" :key="n" cols="12" md="3" v-if="item.permiso">
				<v-card :to="item.to" class="fill-height" @click="irA(item.to)">
					<v-row align="center"  class="py-5">
						<v-icon size="50" color="secondary" class="mx-auto">fad fa-{{item.icon}}</v-icon>
					</v-row>
					<v-card-text align="center">
						<h2>{{_.capitalize(item.title)}}</h2>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {MUTATION_RESET_INTERVIEW, PACIENTES, PERMISOS, PRO, RESET_INTERVIEW, UBICACION} from "@/store/types";
import store from "@/store";
import {mapGetters} from "vuex";

export default {
	name: "Home",
	beforeRouteEnter(to, from, next){
		store.dispatch(RESET_INTERVIEW)
		next();
	},
	computed:{
		...mapGetters({pro:PRO, pacientes:PACIENTES, ubicacion: UBICACION, permisos: PERMISOS}),
		items(){
			return [
				{
					icon: 'hospital',
					title: 'módulo clínica',
					to: {name:'ProVersion'},
					permiso: this.pro && this.permisos.includes('evaluar')
				},
				{
					icon: 'users-medical',
					title: 'Pacientes en sala',
					to: {name:'ListaEspera'},
					permiso: this.permisos.includes('espera')
				},
				{
					icon: 'headset',
					title: 'Teleorientación',
					to: {name:'Teleorientacion'},
					permiso: this.permisos.includes('teleorientacion')
				},
				{
					icon: 'head-side-medical',
					title: 'módulo paciente',
					to:{name: 'DisclaimerPaciente'},
					permiso:this.pacientes
				}
			]
		},
	},
	methods:{
		irA(to){ return this.$router.push(to) }
	}
}
</script>

<style scoped>

</style>
