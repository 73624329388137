<template>
	<v-fade-transition mode="out-in">
		<router-view />
	</v-fade-transition>
</template>

<script>
import {logout} from '@/actions/actions'
export default {
	name: "adminLayout",

}
</script>

<style scoped>
	.calcHeight{height: 100%;}
</style>
