import {deleteCollection, updateCollection} from "@/actions/actions";
import {
	ADD_EVIDENCE,
	ADD_MULTIPLE_EVIDENCE,
	AGE,
	CALLS, CLEAN_ALL_EVIDENCE, DELETE_INTERVIEW,
	DISCLAIMER,
	EVIDENCE,
	FIREBASE_ID, INCREMENT_CALLS,
	INTERVIEW,
	INTERVIEW_USER,
	INTERVIEW_USER_ID,
	MUTATION_AGE, MUTATION_CABINA,
	MUTATION_CALLS,
	MUTATION_DISCLAIMER,
	MUTATION_EVIDENCE,
	MUTATION_FIREBASE_ID,
	MUTATION_INTERVIEW,
	MUTATION_INTERVIEW_USER,
	MUTATION_INTERVIEW_USER_ID,
	MUTATION_RESULTADOS,
	MUTATION_SEDE,
	MUTATION_SEX,
	MUTATION_UBICACION, REMOVE_EVIDENCE, RESET_INTERVIEW,
	RESULTADOS, SET_EVIDENCE,
	SET_RESULTADOS,
	SEX, START_INTERVIEW
} from "../types";

const state = {
	id: null,
	paciente_id:"",
	firebaseId:null,
	evidence: [],
	calls: 0,
	user: {
		primerNombre: "",
		segundoNombre: "",
		primerApellido: "",
		segundoApellido: "",
		tipoIdentificacion: "CC",
		identificacion: "",
		edad: 0,
		sexo: "",
		discapacidad:false,
		cancer: false,
		telefono: ""
	},
	resultados:{},
	disclaimer: process.env.NODE_ENV === 'development'
};

const getters = {
	[INTERVIEW]: state => { return state.id; },
	[EVIDENCE]: state => { return state.evidence; },
	[INTERVIEW_USER]: state => { return state.user; },
	[INTERVIEW_USER_ID]: state => { return state.paciente_id; },
	[AGE]: state => { return state.user.edad; },
	[SEX]: state => { return state.user.sexo; },
	[CALLS]: state => { return state.calls; },
	[FIREBASE_ID]: state => { return state.firebaseId; },
	[INTERVIEW_USER]: state => { return state.user; },
	[RESULTADOS]: state => { return state.resultados; },
	[DISCLAIMER]: state => { return state.disclaimer; },
};

const mutations = {
	[MUTATION_INTERVIEW]: (state, payload) => {state.id= payload},
	[MUTATION_EVIDENCE]: (state, payload) => {state.evidence = payload},
	[MUTATION_CALLS]: (state, payload) => {state.calls = payload},
	[MUTATION_INTERVIEW_USER]: (state, payload) => { _.merge(state.user, payload) },
	[MUTATION_INTERVIEW_USER_ID]: (state, payload) => { state.paciente_id = payload },
	[MUTATION_AGE]: (state, payload) => {state.user.age = payload},
	[MUTATION_SEX]: (state, payload) => {state.user.sex = payload},
	[MUTATION_FIREBASE_ID]: (state, payload) => {state.firebaseId = payload},
	[MUTATION_RESULTADOS]: (state, payload) => { state.resultados = payload },
	[MUTATION_DISCLAIMER]: (state, payload) => {state.disclaimer= payload},
};

const actions = {
	[START_INTERVIEW]: ({commit, dispatch}, data) => {
		commit(MUTATION_FIREBASE_ID, data.firebaseId);
		if(data.entrevista) commit(MUTATION_INTERVIEW, data.entrevista);
		if(data.evidence)commit(MUTATION_EVIDENCE, data.evidence);
		dispatch(INCREMENT_CALLS);
	},
	[ADD_MULTIPLE_EVIDENCE]:async ({dispatch}, data) => {
		for(const index in data){
			await dispatch(ADD_EVIDENCE, data[index])
		}
	},
	[ADD_EVIDENCE]: async({commit, state}, data) => {
		let findIndex = _.findIndex(state.evidence,['id', data.id])
		if(findIndex>=0 && state.evidence[findIndex].choice_id !== data.choice_id && data.id!=='p_42') {
			state.evidence[findIndex].choice_id=data.choice_id
		}
		else{
			const filteredData = _.uniqBy([...state.evidence, data],"id");
			commit(MUTATION_EVIDENCE, filteredData)
		}
		if(state.firebaseId)
			await updateCollection('interviews', state.firebaseId, {evidence: state.evidence})
	},
	[SET_EVIDENCE]: async ({commit, state}, data) => {
		const filteredData = _.uniqBy(data,"id")
		commit(MUTATION_EVIDENCE, filteredData)
		if(state.firebaseId)
			await updateCollection('interviews', state.firebaseId, {evidence: state.evidence})
	},
	[REMOVE_EVIDENCE]:async ({commit, state}, data) => {
		let evidence =  state.evidence
		evidence.splice(_.findIndex(evidence, ['id', data]),1)
		commit(MUTATION_EVIDENCE, evidence)
		if(state.firebaseId)
			await updateCollection('interviews', state.firebaseId, {evidence: state.evidence})
	},
	[CLEAN_ALL_EVIDENCE]: async ({commit,state}) => {
		commit(MUTATION_EVIDENCE, [])
		if(state.firebaseId)
			await updateCollection('interviews', state.firebaseId, {evidence: null})

	},
	[INCREMENT_CALLS]: async ({commit, state}) => {
		const calls = state.calls+1
		commit(MUTATION_CALLS, calls)
		await updateCollection('interviews',state.firebaseId, {calls:calls})
	},
	[RESET_INTERVIEW]: ({state, commit}, nuevaEntrevista)  =>{
		state.id = null;
		state.evidence = [];
		state.calls = 0 ;
		state.firebaseId = null;
		state.resultados = {}
		if(nuevaEntrevista) {
			state.user = {
				nombre: "",
				apellidos: "",
				identificacion: "",
				edad: 0,
				sexo: "",
				discapacidad:false,
				cancer: false
			}
			state.disclaimer = false;
		}
	},
	[DELETE_INTERVIEW]: async ({dispatch, state}, nuevaEntrevista) => {
		if(state.firebaseId){
			await deleteCollection('interviews', state.firebaseId)
		}
		dispatch(RESET_INTERVIEW, nuevaEntrevista)
	},
	[SET_RESULTADOS]: async ({commit, state}, resultados) => {
		await updateCollection('interviews',state.firebaseId, resultados)
		commit(MUTATION_RESULTADOS, resultados)
	},
};

export default {
	state,
	getters,
	mutations,
	actions
}
