<template>
	<div>
		<h1 class="text-center mb-2">¿Actualmente tiene alguna de estas condiciones?</h1>
		<div class="text-center mb-8">Si la respuesta es positiva seleccione la condición, de lo contrario, continue en 'no, no tengo'</div>
		<v-row :style="{width:width}" class="mx-auto">
			<v-col v-for="(ruta,n) in rutas" :key="ruta.value" cols="12" sm="3" >
				<v-card max-width="200" elevation="5" class="mx-auto fill-height" :disabled="ruta.disabled" @click="irARuta(ruta.value)">
					<v-card-text class="text-center">
						<v-icon color="primary" size="80" class="mx-auto">far fa-{{ruta.icon}}</v-icon>
					</v-card-text>
					<v-card-actions>
						<div class="mx-auto text-h6 text-center">
							{{ruta.text}}
						</div>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card max-width="200" elevation="5" class="mx-auto" @click="next">
					<v-card-text class="text-center">
						<v-icon color="primary" size="80" class="mx-auto">far fa-ban</v-icon>
					</v-card-text>
					<v-card-actions>
						<div class="mx-auto text-h6 text-center">Ninguna de las anteriores</div>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="preguntasRutas" fullscreen>
			<v-card class="fill-height">
				<v-toolbar dark color="primary" >
					<v-toolbar-title>Ruta seleccionada</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn dark text @click="preguntasRutas = false">
							<v-icon>far fa-times</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
					<preguntas-ruta :ruta-seleccionada="rutaSeleccionada"/>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {API_URL, EVIDENCE, INTERVIEW, SEX} from "@/store/types";
import {fetchOptions, refresh_token} from "@/actions/actions";
import * as types from "@/store/types";

export default {
	name: "RutasPaciente",
	components: {
		'preguntasRuta': () => import('./preguntasRuta.vue'),
	},
	data: ()=>({
		preguntasRutas:false,
		rutaTrauma:false,
		rutaSeleccionada: ""
	}),
	created(){
		Event.$on('respuestaRuta', (ruta)=>{
			this.preguntasRutas = false
			this.diagnose()
			Event.$emit('siguienteInterview', ruta)
		})
	},

	computed:{
		...mapGetters({ SEX, EVIDENCE, API_URL, INTERVIEW }),
		width: function (){
			return this.$vuetify.breakpoint.lgAndUp ? '50%': '100%'
		},
		rutas:function (){
			return [
				{value:"embarazoPaciente", text: "Embarazo", icon: "person-pregnant", disabled: this.SEX !== "f"},
				{value:"irasPaciente", text: "Tos, estornudos, congestión y similares", icon: "lungs-virus", disabled: false},
				{value:"quemaduraPaciente", text: "Quemadura", icon: "fire-flame", disabled: false},
				{value:"traumaPaciente", text: "Golpe o Trauma", icon: "person-falling-burst", disabled: false},
			]
		}
	},
	methods:{
		next(){
			Event.$emit('siguienteInterview', 'buscadorsintomas')
		},
		irARuta(ruta){
			this.rutaSeleccionada = ruta
			this.preguntasRutas = true
		},
		diagnose(){
			cargando( true)
			const data={
				mode: "diagnosis",
				evidence: this.EVIDENCE
			}

			fetch(`${this.API_URL}interview/${this.INTERVIEW}`, fetchOptions({method:"POST", auth, body: data}))
				.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })
				.then( response =>{
					this.$store.dispatch(types.INCREMENT_CALLS);
					this.should_stop = response.result.should_stop;
					this.has_emergency_evidence = response.result.has_emergency_evidence
					Event.$emit('updateDiagnostico', _.slice(response.result.conditions,0,4))
				})
				.catch(e=>{
					Promise.resolve(e).then(error=>{
						if(JSON.parse(error).message === 'Unauthorized'){ refresh_token(); this.diagnose()}
					})
				})
				.finally(()=>{ cargando(false);})
		}
	}
}
</script>

<style scoped>

</style>
