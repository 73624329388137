<template>
	<v-container>
		<v-card>
			<v-card-title>
				<div class="text-h4 primary--text">Prueba telsit</div>
			</v-card-title>
			<v-card-text>

				<v-row>
					<v-col>
						<v-text-field v-model="ip" label="ingrese ip"/>
					</v-col>
					<v-col class="d-flex flex-column mt-5" v-if="esperandoRespuesta">
						<div class="mx-auto">Esperando resultados de los signos vitales</div>
						<v-progress-circular  class="mx-auto"
							indeterminate
							color="primary"
						/>
					</v-col>
					<v-col class="d-flex justify-center mt-5" v-else>
						<v-btn class="" outlined @click="iniciarTelsit">iniciar telsit</v-btn>
					</v-col>
					<v-col class="d-flex justify-center mt-5">
						<v-btn class="" outlined @click="iniciarVideo">iniciar video</v-btn>
					</v-col>
					<v-col class="d-flex justify-center mt-5">
						<v-btn class="" outlined @click="reset">reset</v-btn>
					</v-col>
				</v-row>

				<v-row>
					<v-card>
						<v-card-title>datos en db prueba:</v-card-title>
						<v-card-text>

							{{interviewData}}
						</v-card-text>
					</v-card>
				</v-row>
			</v-card-text>

		</v-card>



	</v-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import 'firebase/firestore'


export default {

	name: "SignosVitales",

	data:()=>({
		ip: "172.20.103.124:8000",
		interviewData: null,
		esperandoRespuesta: false,
		ref: firebase.firestore().collection('testTelsit').doc('test'),
		firebaseId: null,

	}),

	created: async function() {
		const ref = firebase.firestore().collection('testTelsit').doc('test')

		await this.reset();

		this.ref.onSnapshot( doc =>{
			this.firebaseId = doc.id;
			this.interviewData = doc.data();
		})
	},
	watch:{
		interviewData: function (val){ if(val.signosVitales) this.esperandoRespuesta = false }
	},
	methods:{
		iniciarTelsit(){
			this.esperandoRespuesta = true;
			axios.post(`https://${this.ip}/`, {"start": true, interview_id: this.firebaseId});
		},
		iniciarVideo(){
			axios.post(`https://${this.ip}/video/`, {"start": true});
		},
		reset: async function(){
			await this.ref.set({ ip:this.ip, signosVitales: firebase.firestore.FieldValue.delete() }, {merge: true})
			this.esperandoRespuesta= false;
		}
	}


}
</script>

<style scoped>

</style>
