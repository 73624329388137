<template>
    <div class="fill-height d-flex align-center">
	    <v-card>
			<v-card-text class="pt-5">
				<v-form ref="iraForm">
					<div class="text-h5 primary--text mt-5">Vacunación</div>
					<v-radio-group v-model="vacunacion" row required :rules="[v=>!!v||'Debe seleccionar un esquema de vacunación']">
						<v-radio label="Sin vacuna" value="p_330"/>
						<v-radio label="Esquema incompleto" value="p_331"/>
						<v-radio label="Esquema completo" value="p_332"/>
					</v-radio-group>
					<v-row v-if="esquemaCompleto">
						<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'scheme'})" :key="`scheme_${n}`">
							<v-switch :label="concept.text" v-model="concept.value" hide-details/>
						</v-col>
					</v-row>

					<div class="text-h5 primary--text mt-5">Síntomas</div>
					<v-row>
						<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'symptom'})" :key="`symptom_${n}`">
							<v-switch :label="concept.text" v-model="concept.value" hide-details/>
						</v-col>
					</v-row>
					<v-row v-if="showFiebre">
						<v-col cols="12">
							<div class="subtitle-1 secondary--text">Nivel de fiebre</div>
							<v-slider hint="Seleccione la intensidad del dolor abdominal" max="3" min="0"
									  :tick-labels="['No medida','Baja (37-38)','Alta (38-40)','Grave (>40)']"
									  v-model="intesidad">
							</v-slider>
						</v-col>
					</v-row>
					<v-row v-if="showTos">
						<v-col cols="12">
							<div class="subtitle-1 secondary--text">Duración de la tos</div>
							<v-slider hint="Seleccione el tiempo que lleva tosiendo" max="2" min="0"
									  :tick-labels="['Menos de tres semanas','Entre tres y ocho semanas','Más de ocho semanas']"
									  v-model="tiempoTos">
							</v-slider>
						</v-col>
					</v-row>
					<div class="text-h5 primary--text mt-5">Covid</div>
					<v-row>
						<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'covid'})" :key="`covid_${n}`">
							<v-switch :label="concept.text" v-model="concept.value" hide-details/>
						</v-col>
					</v-row>
					<div class="text-h5 primary--text mt-5">Factores de Riesgo</div>
					<v-row>
						<v-col cols="auto" v-for="(concept, n) in _.filter(concepts, {category: 'risk_factor'})" :key="`risk_factor_${n}`">
							<v-switch :label="concept.text" v-model="concept.value" hide-details/>
						</v-col>
					</v-row>
				</v-form>

			</v-card-text>
			<v-card-actions>
				<v-spacer/>
				<v-btn outlined color="primary" @click="enviar()">enviar</v-btn>
			</v-card-actions>
		</v-card>
    </div>
</template>

<script>
export default {
	name: "embarazo",
	data:()=>({
		vacunacion:null,
		concepts:{
			p_360: {text: 'Hace más de 6 meses', value: false, category: 'scheme'},
			p_359: {text: 'Refuerzo de vacuna', value: false, category: 'scheme'},

			s_98: {text: 'Fiebre', value: false, category: 'symptom'},
			s_102: {text: 'Tos', value: false, category: 'symptom'},
			s_88: {text: 'Dificultad para respirar', value: false, category: 'symptom'},
			s_331: {text: 'Congestión nasal', value: false, category: 'symptom'},
			s_2100: {text: 'Fatiga', value: false, category: 'symptom'},
			s_20: {text: 'Dolor de garganta', value: false, category: 'symptom'},
			s_21: {text: 'Dolor de cabeza', value: false, category: 'symptom'},

			s_2256: {text: 'Contacto cercano con caso COVID-19', value: false, category: 'covid'},
			s_2254: {text: 'Cuidado directo de caso COVID-19', value: false, category: 'covid'},
			s_2257: {text: 'Otro tipo de contacto con caso COVID-19', value: false, category: 'covid'},
			s_2258: {text: 'Sin contacto con caso COVID-19', value: false, category: 'covid'},

			p_164: {text: 'Epoc', value: false, category: 'risk_factor'},
			p_83: {text: 'Sistema Inmune debilitado', value: false, category: 'risk_factor'},



		},
		intesidad: 0,
		tiempoTos:0,
		painId:['s_1820','s_99','s_100','s_2000'],
		tosId:['s_103','s_1858','s_106'],
	}),
	computed:{
		esquemaCompleto() {
			return this.vacunacion === 'p_332'
		},
		showFiebre() {
			if(this.concepts) return this.concepts['s_98'].value;
			else return false;
		},
		showTos() {
			if(this.concepts) return this.concepts['s_102'].value;
			else return false;
		}
	},
	methods: {
		enviar(){
			if(this.$refs.iraForm.validate()){
				let evidence = [
					{"id": this.vacunacion, "source": "initial", "choice_id": "present"}
				]
				_.forIn(this.concepts, function(values, key) {
					evidence.push({"id": key, "source": "initial", "choice_id": values.value?"present":"absent"})
				});
				if(this.showFiebre) {
					evidence.push({"id": this.painId[this.intesidad], "source": "initial", "choice_id": "present"})
				}
				if(this.showTos) {
					evidence.push({"id": this.tosId[this.tiempoTos], "source": "initial", "choice_id": "present"})
				}

				this.$emit("input", evidence)
			}
		}
	}
}
</script>

<style scoped>

</style>
