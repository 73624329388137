<template>
	<v-form v-model="riskFactorForm" ref="riskFactorForm">
		<v-row justify="center">
			<v-col :cols="fullscreen?12:10" :class="{'pa-0':fullscreen}">
				<v-card :flat="fullscreen">
					<v-card-text>
						<h2 class="mb-5 secondary--text" v-if="!fullscreen">Factores de riesgo comunes</h2>
						<v-row  align="center" v-for="(riskfactor,i) in riskfactorsTemp" :key="`risk_${i}`" no-gutters>
							<v-col :cols="$vuetify.breakpoint.smAndDown?'12':''">
								<h3>{{riskfactor.name}} <span v-if="riskfactor.common_name !== riskfactor.name">({{riskfactor.common_name}})</span></h3>
								<small v-if="riskfactor.id==='p_7'">* IMC: EL Indice de Masa Corporal se usa para identificar las categorías de peso que pueden llevar a problemas de salud.</small>
								<small v-if="riskfactor.id==='p_264'">* lesión física reciente: Es un término general que se refiere al daño causado por accidentes, caídas, golpes, otras causas.</small>
							</v-col>
							<v-col cols="auto">
								<v-radio-group v-if="show[i]" v-model="risks[i].choice_id" row :rules="rules.required" @change="submitRiskFactor()">
									<v-radio label="Si" value="present"></v-radio>
									<v-radio label="No se" value="unknown"></v-radio>
									<v-radio label="No" value="absent"></v-radio>
								</v-radio-group>
							</v-col>
							<v-col cols="12">
								<v-divider/>
							</v-col>
						</v-row>
						<v-row>
						</v-row>
					</v-card-text>
					<v-card-actions v-if="!fullscreen">
						<v-spacer/>
						<v-btn outlined color="primary" @click="siguiente">Siguiente</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import validationRules from "@/actions/validationRules";
import * as types from '@/store/types'
import {mapActions, mapGetters} from "vuex";
import {ADD_MULTIPLE_EVIDENCE, EVIDENCE, INTERVIEW, INTERVIEW_USER_ID} from "@/store/types";
import axios from "axios";
import {fetchOptions} from "@/actions/actions";

export default {
	name: "riskFactor",
	props:{ 'riskfactors': {default: ()=>{return []}}, 'fullscreen':{ default: false, type: Boolean} },
	data:()=>({
		riskFactorForm: false,
		riskfactorsTemp: [],
		factoresPredeterminados:[
			{common_name: "Hipertensión diagnosticada", id:"p_9", name:"Hipertensión diagnosticada"},
			{common_name: "Fumar cigarrillos directa o indirectamente", id:"p_28", name:"Fumar cigarrillos"},
			{common_name: "Antecedente personal o familiar de alergia", id:"p_287", name:"Antecedente personal o familiar de alergia"},
			{common_name: "Colesterol alto", id:"p_10", name:"Colesterol alto"},
			{common_name: "Cirugía reciente", id:"p_8", name:"Cirugía reciente"}
		],
		evidenceRiskFactor:[],
		risks:[],
		show:[],
		rules: validationRules
	}),
	computed:{
		...mapGetters({
			api_url: types.API_URL,
			interviewId: INTERVIEW,
			evidence: EVIDENCE
		})
	},

	async created() {
		Event.$on('clearFactoresRiesgo', ()=>{this.clearRiesgos()})

		const response = await fetch(`${this.api_url}interview/user/${this.interviewId}`, fetchOptions({
			method: 'POST',
			auth,
			body: {
				mode: "suggest",
				evidence_risk_factors: true,
				evidence: this.evidence
			}
		}))

		if(!response.ok) throw new Error( await response.text() );
		const evidenceRiskFactor = await response.json();

		if( evidenceRiskFactor.result) this.evidenceRiskFactor = evidenceRiskFactor.result

		this.listaFactoresRiesgo()
	},
	watch:{
		riskfactors: function(){ this.listaFactoresRiesgo() }
	},
	methods:{
		...mapActions({addMultipleEvidence:ADD_MULTIPLE_EVIDENCE, }),

		clearRiesgos(){
			if(this.$refs.riskFactorForm) this.$refs.riskFactorForm.reset()
		},
		listaFactoresRiesgo(){
			this.riskfactorsTemp =_.uniqBy([..._.cloneDeep(this.riskfactors),...this.factoresPredeterminados, ...this.evidenceRiskFactor], 'id');


			this.riskfactorsTemp.forEach((item, index) =>{
				this.risks.push({ id: item.id, choice_id: "", source: "suggest"})
				this.show[index]=true
			})
		},
		siguiente(){
			if(this.$refs.riskFactorForm.validate()){
				cargando(true);
				this.addMultipleEvidence(this.risks).then(() => {
					Event.$emit('siguienteInterview', 'cuestionario')
				});
			}
		},
		submitRiskFactor(){
			const filter = _.filter(this.risks, function(o) { return !!o.choice_id; })
			this.$emit('input', filter)
		}
	}
}
</script>

<style scoped>

</style>
