import { render, staticRenderFns } from "./Divider.vue?vue&type=template&id=8a457db2&scoped=true"
import script from "./Divider.vue?vue&type=script&lang=js"
export * from "./Divider.vue?vue&type=script&lang=js"
import style0 from "./Divider.vue?vue&type=style&index=0&id=8a457db2&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a457db2",
  null
  
)

export default component.exports