<script>
import {analizarSignos, imc, triageFunction, updateCollection} from "@/actions/actions";
import {ADD_EVIDENCE, API_URL,  SIGNOSVITALES} from "@/store/types";
import {mapActions, mapGetters} from "vuex";

export default {
	name: "signosVitalesCard",
	props: ['value', 'firebaseId'],
	data:()=>({
		signoVitales:{
			saturacion: null,
			frecuencia_cardiaca:null,
			frecuencia_respiratoria: null,
			sistolica: null,
			diastolica: null,
			peso: null,
			altura: null,
			imc: null,
			temperatura: null
		}
	}),
	created(){
		if(process.env.NODE_ENV==='development'){
			this.signoVitales = {"temperatura":35.9,"frecuencia_cardiaca":56,"frecuencia_respiratoria":17,"saturacion":97,"sistolica":110,"diastolica":56}
		}
	},
	computed:{
		...mapGetters({
			criteriosSignosVitales: SIGNOSVITALES, API_URL
		})
	},
	methods:{
		...mapActions([ADD_EVIDENCE]),
		async iniciarManual(){
			cargando(true);

			if(this.signoVitales.peso && this.signoVitales.altura)
				this.signoVitales.imc = imc(this.signoVitales.peso, this.signoVitales.altura)

			await analizarSignos(this.signoVitales, this.firebaseId)

			await updateCollection('interviews',this.firebaseId,{"evaluacion_triage": true})

			await triageFunction(this.firebaseId, this.api_url, false)
			cargando(false);

			this.$emit('input', false)
		}
	}
}
</script>

<template>
	<v-dialog v-model="value" persistent width="90%">
		<v-card>
			<v-card-title>
				<h1 class="primary--text">Ingrese los valores de los signos vitales</h1>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col md="8" offset-md="2">
						<v-form ref="svForm">
							<v-text-field label="Frecuencia cardiaca (ppm)" type="number" v-model.number="signoVitales.frecuencia_cardiaca"/>
							<v-text-field label="Temperatura (C)" type="number" v-model.number="signoVitales.temperatura"/>
							<v-text-field label="Frecuencia respiratoria (resp/min)" type="number" v-model.number="signoVitales.frecuencia_respiratoria"/>
							<v-text-field label="Saturación de Oxigeno (SpO2)" type="number" v-model.number="signoVitales.saturacion"/>
							<v-text-field label="Presión Sistólica (mmHg)" type="number" v-model.number="signoVitales.sistolica"/>
							<v-text-field label="Presión Diastólica (mmHg)" type="number" v-model.number="signoVitales.diastolica"/>
							<v-text-field label="Peso (kg)" type="number" v-model.number="signoVitales.peso"/>
							<v-text-field label="Estatura (cm)" type="number" v-model.number="signoVitales.altura"/>
						</v-form>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn outlined @click="$refs.svForm.reset(); $emit('input', false )">cancelar</v-btn>
				<v-btn outlined @click="iniciarManual">continuar</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped>

</style>