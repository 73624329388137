import * as types from '../types'

const state = {


};

const getters = {


};

const mutations = {

};

const actions = {};

export default {
	state,
	getters,
	mutations,
	actions
}
