<template>
	<v-container fuid :class="$vuetify.breakpoint.smAndDown?'altura':'fill-height'">
		<v-row align="center" justify="center" class="fill-height">
			<v-card width="100%" :flat="$vuetify.breakpoint.smAndDown">
				<v-card-text>
					<h1 class="mb-10 primary--text">Entrevista</h1>
					<component :is="etapa" :interview="this.$attrs.interview" :riskfactors="this.$attrs.riskFactors"/>
				</v-card-text>
				<v-card-actions class="mt-10">
					<v-btn outlined color="secondary" @click="reiniciar()">Reiniciar</v-btn>
					<v-btn outlined color="orange" class="ml-10" @click="reiniciar(true)">Nueva Consulta</v-btn>
				</v-card-actions>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>

import buscadorSintomas from "@/components/buscardorSintomas"
import rutasPaciente from "@/components/rutas/RutasPaciente.vue"
import RiskFactor from "@/components/riskFactor";
import cuestionario from '@/components/cuestionario';
import sugerencias from '@/components/sugerencias';
import * as types from "@/store/types"
import {fetchOptions, updateCollection, refresh_token} from "@/actions/actions";
import {mapActions, mapMutations} from "vuex";
import {
	ADD_EVIDENCE,
	DELETE_INTERVIEW,
	MUTATION_DISCLAIMER, MUTATION_FIREBASE_ID,
	SET_RESULTADOS
} from "@/store/types";

const {mapGetters} = require("vuex");

export default {
	name: 'interview',
	components: {riskfactor: RiskFactor, buscadorsintomas:buscadorSintomas, cuestionario, sugerencias, rutasPaciente },
	data: ()=>({
		diagnosticos: [],
		etapa: "rutasPaciente",
		formError:null,

	}),
	async created(){
		Event.$on('siguienteInterview', etapa => { this.etapa = etapa; });
		Event.$on('resultado', () => { this.terminar() })
		Event.$on('updateDiagnostico', diagnosticos => { this.diagnosticos = diagnosticos })

		if(!this.firebaseId){
			const docsRef = await db.collection('interviews').where('id', '==', this.$attrs.interview).limit(1).get()

			const doc = docsRef?.docs[0]
			if( !doc?.exists )
				return this.$router.push('/')

			this.MUTATION_FIREBASE_ID(doc.id);
		}
	},
	computed:{
		...mapGetters({
			evidence: types.EVIDENCE,
			api_url: types.API_URL,
			firebaseId: types.FIREBASE_ID,
			ubicacion: types.UBICACION
		})
	},
	methods: {
		...mapMutations({ setDisclaimer: MUTATION_DISCLAIMER, MUTATION_FIREBASE_ID}),
		...mapActions({addEvidence: ADD_EVIDENCE, deleteInterview: DELETE_INTERVIEW, resultados: SET_RESULTADOS,}),

		reiniciar(nuevaEntrevista=false){
			this.deleteInterview(nuevaEntrevista);
			if(nuevaEntrevista) this.setDisclaimer(false)
			const route = nuevaEntrevista?'DisclaimerPaciente':'DatosPaciente'
			this.$router.push({name:route});
		},
		terminar(){
			cargando(true);
			let triage = {
				mode: "triage",
				evidence: this.evidence
			}

			let specialist = {
				mode: "recommend_specialist",
				evidence: this.evidence
			}
			updateCollection('interviews', this.firebaseId, {
				terminada: true
			})

			const p1=  fetch(`${this.api_url}interview/${this.$attrs.interview}`, fetchOptions({method: 'POST', auth, body: triage}))
				.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })

			const p2=  fetch(`${this.api_url}interview/${this.$attrs.interview}`, fetchOptions({method: 'POST', auth, body: specialist}))
				.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })

			Promise.all([p1,p2]).then(values=>{
				const getTriage ={
					emergency_ambulance: 1,
					emergency:2,
					consultation_24:3,
					consultation:4,
					self_care:5
				};

				this.resultados(
					{
						triage_level: getTriage[values[0].result.triage_level],
						triage: values[0].result,
						specialist : values[1].result,
						diagnosticos: this.diagnosticos
					}
				)
				cargando(false);

				return this.$router.push({name: 'Resultados' }).catch(err=>{})
			})
				.catch(e=>{
					Promise.resolve(e).then(error=>{
						if(JSON.parse(error).message === 'Unauthorized' ){ refresh_token(); this.buscar() }
					});
					console.log(e);
				})
				.finally(()=>{cargando(false)})
		}
	}

}
</script>

<style scoped>
.altura {height: calc(100% - 30px)}
.v-alert__content{overflow: auto}
</style>
