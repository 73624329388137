<template xmlns:slot="http://www.w3.org/1999/html">
	<v-form v-model="sugerenciasForm" ref="sugerenciasForm" v-if="show">
		<v-row justify="center">
			<v-col cols="10">
				<v-card>
					<v-card-text>
						<h2 class="mb-5 secondary--text">Tiene alguno de los siguientes sintomas?</h2>
						<v-row  align="center" v-for="(sugerencia,i) in sugerencias" :key="`sugerencia_${i}`" no-gutters>
							<v-col :cols="$vuetify.breakpoint.smAndDown?'12':''">
								<v-checkbox hide-details v-model="sintomas" :value="sugerencia.id">
									<template v-slot:label>
										<div>{{sugerencia.name}} <span v-if="sugerencia.common_name !== sugerencia.name">({{sugerencia.common_name}})</span></div>
									</template>
								</v-checkbox>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn outlined color="primary" @click="grabar">Siguiente</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import * as types from "@/store/types";
import {mapActions, mapGetters} from "vuex";
import {fetchOptions, refresh_token} from "@/actions/actions";
import { ADD_MULTIPLE_EVIDENCE} from "@/store/types";

export default {
	name: "sugerencias",
	data:()=>({
		sintomas: [],
		sugerencias: [],
		sugerenciasForm: false,
		show: false,
	}),
	props:{
		interview: { required: true }
	},
	computed: {
		...mapGetters({api_url: types.API_URL, evidence: types.EVIDENCE}),
	},
	created() {
		cargando(true);
		const data={
			mode: "suggest",
			evidence: this.evidence
		}

		fetch(`${this.api_url}interview/${this.interview}`, fetchOptions({method: "POST", auth, body: data}))
			.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })
			.then( response => {
				this.$store.dispatch(types.INCREMENT_CALLS);

				if(response.result.length === 0) Event.$emit('siguienteInterview', 'riskfactor');
				else {
					this.show = true;
					this.sugerencias = response.result
				}
			})
			.finally(()=>{ cargando(false); })
			.catch(e=>{
				if(e.name === "AbortError") { }
				else {
					Promise.resolve(e).then(error=>{
						console.log(error)
						if(JSON.parse(error).message === 'Unauthorized' ){ refresh_token(); }
					});
				}
			})
	},
	methods:{
		...mapActions({addMultipleEvidence:ADD_MULTIPLE_EVIDENCE}),

		grabar(){
			let evidencias = [];
			this.sintomas.forEach(id =>{
				evidencias.push({ id: id, "choice_id": "present", source: "suggest"})
			})
			this.addMultipleEvidence(evidencias).then(() => {
				Event.$emit('siguienteInterview', 'riskfactor')
			});
		}
	}
}
</script>

<style scoped>

</style>
