import * as types from '../types'
import {fetchOptions, refresh_token, updateCollection} from "@/actions/actions";
import store from "@/store";
import {API_URL, CONCEPTOS, EXPIRES, MUTATION_CONCEPTOS, MUTATION_EXPIRES} from "../types";
import moment from "moment";
import {mensajesSalida} from "../../../messages";

const state = {
	conceptos: [],
	expires: null,
	signosVitales: [],
	expiresSignosVitales: null,
	express: false,
	esi: true,
	mensajes: mensajesSalida,
};

const getters = {
	[types.CONCEPTOS]: state => {
		return state.conceptos;
	},
	[types.EXPIRES]: state => {
		return state.expires;
	},
	[types.SIGNOSVITALES]: state => {
		return state.signosVitales;
	},
	[types.EXPIRESSIGNOSVITALES]: state => {
		return state.expiresSignosVitales;
	},
	[types.EXPRESS]: state => {
		return state.express;
	},
	[types.ESI]: state => {
		return state.esi;
	},
	[types.MENSAJES]: state => {
		return state.mensajes;
	},

};

const mutations = {
	[types.MUTATION_CONCEPTOS]: (state, payload) => {
		state.conceptos = payload
		state.expires = moment()
	},

	[types.MUTATION_SIGNOSVITALES]: (state, payload) => {
		state.signosVitales = payload
		state.expiresSignosVitales = moment()
	},

	[types.MUTATION_EXPRESS]: (state, payload) => {
		state.express = payload
	},
	[types.MUTATION_ESI]: (state, payload) => {
		state.esi = payload
	},
};

const actions = {
	[types.SET_CONCEPTOS]: ({dispatch, commit}) => {
		return fetch(`${store.getters[API_URL]}fetchConcepts`, fetchOptions({auth}))
			.then(response => {
				if (response.ok) return response.json(); else throw response.text();
			})
			.then(response => {
				commit(types.MUTATION_CONCEPTOS, response);
				toast.fire({title: "Conceptos actualizados", icon: "success"})
			})
			.catch(e => {
				Promise.resolve(e).then(error => {
					if (JSON.parse(error).message === 'Unauthorized') {
						refresh_token();
						dispatch(types.SET_CONCEPTOS)
					}
				});
				console.log(e);
			})

	},

	[types.SET_SIGNOSVITALES]: ({dispatch, commit}) => {
		return fetch(`${store.getters[types.API_URL]}fetchNivelesSignosVitales`, fetchOptions({auth}))
			.then(response => {
				if (response.ok) return response.json(); else throw response.text();
			})
			.then(async values => {
				commit(types.MUTATION_SIGNOSVITALES, values);
				await db.collection('signosVitales').doc('default').set(values)
				await toast.fire({title: "Signos Vitales actualizados", icon: "success"})
			})
			.catch(e => {
				console.log(e);

				Promise.resolve(e).then(error => {
					if (JSON.parse(error).message === 'Unauthorized') {
						refresh_token();
						dispatch(types.SET_SIGNOSVITALES)
					}
				});
			})
	},
}


export default {
	state,
	getters,
	mutations,
	actions
}
