<template>
	<v-row align="center" justify="center" class="fill-height">
		<v-card width="100%">
			<v-card-text>
				<h1 class="mb-10 primary--text">Exámen</h1>
				<component :is="etapa" v-model="startData[etapa]"/>
			</v-card-text>
			<v-card-actions>
				<v-btn outlined color="orange" @click="reiniciar">Reinciar</v-btn>
			</v-card-actions>
		</v-card>
	</v-row>
</template>

<script>
import startData from '@/components/StartData'
import viajes from '@/components/viajes'
import {mapGetters} from "vuex";
import * as types from '@/store/types'
import {createCollection, fetchOptions} from "@/actions/actions";
import moment from "moment";

export default {
	name: 'start',
	components:{ startdata: startData, viajes },
	data: ()=>({
		etapa: 'startdata',
		startData: {startdata:{}, viajes:{} }
	}),
	created(){
		Event.$on('siguienteStart', etapa => {
			if(etapa === 'interview'){
				cargando(true);
				const data = {
					sex: this.sex,
					age: this.age,
					...this.startData.startdata,
					...this.startData.viajes
				}

				fetch(`${this.api_url}interview/start`, fetchOptions({method: 'POST', auth, body: data}))
					.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })
					.then( response =>{
						moment.locale('es-mx');
						createCollection('interviews', {id:response.entrevista, user: this.$store.getters[types.USER_ID], date: Date.now() }).then(firebaseId=> {
							this.$store.commit(types.MUTATION_FIREBASE_ID, firebaseId);
							this.$store.commit(types.MUTATION_INTERVIEW, response.entrevista);
							this.$store.commit(types.MUTATION_EVIDENCE, response.evidence);
							this.$router.push({name: 'Interview', params: {interview: response.entrevista, riskFactors: response.factores_riesgo }})
						})
					} )
					.finally(()=>{ cargando(false) })
			}
			else{ this.etapa = etapa }

		})
	},
	computed:{
		...mapGetters({ age: types.AGE, sex:types.SEX, api_url: types.API_URL })
	},
	methods: {
		reiniciar(){
			this.$store.commit(types.MUTATION_EVIDENCE, null);
			this.$store.commit(types.MUTATION_INTERVIEW, null);
			this.$router.push({name:"Home"});
		}
	}


}
</script>
