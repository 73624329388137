<template>
	<v-form v-model="sintomasForm" ref="sintomasForm">
		<v-row justify="center">
			<v-col cols="12" md="10" v-if="!fullscreen">
				<v-card>
					<v-card-title>
						<h2 class="secondary--text word-break">1. Iniciemos identificando los síntomas</h2>
					</v-card-title>
					<v-card-text>
						<div v-if="sintomas.length ===0" class="accent-text text-h6 text-justify">Escribe en el buscador abajo, de a uno, los síntomas que presentas, aparecerá una lista de coincidencias. Posteriormente, selecciona uno o varios ítems. Repite el proceso con todos los síntomas que presentes. Una vez termines de colocar todos los síntomas dale clic en "siguiente".</div>

						<div v-if="!valid" class="accent-text text-h6 text-justify mt-3">Recuerda que para poder realizar la evaluación debes ingresar al menos un (S)síntoma. Un síntoma es el motivo inmediato de porque estás realizando la consulta, ej. un dolor, sangrado, fiebre, etc. </div>

						<v-chip v-if="sintomas.length" v-for="(sintoma,i) in sintomas" color="primary" close class="ma-2" :key="`sintoma_${i}`" @click:close="removeSintoma(i)">
							{{ labels[sintoma.id] }} ({{checkType(sintoma.id)}})
						</v-chip>
					</v-card-text>
				</v-card>


			</v-col>
			<v-col cols="12" :md="fullscreen?12:10">
				<v-card :flat="fullscreen">
					<v-card-text>
						<v-text-field outlined v-model="sintoma" label="Buscar síntomas" @input="buscar" append-icon="fad fa-search" @click:append="buscar" clearable
									  placeholder="ej: dolor de espalda"
						/>
						<template v-if="showResults">
							<h3 class="accent--text">Selecciona una o varias de las coincidencias</h3>
							<v-chip outlined color="secondary" v-for="(resultado,i) in searchResultFilter" class="ma-2" :key="`resultado_${i}`" @click="addSintoma(i)">{{resultado.label}} ({{checkType(resultado.id)}})</v-chip>

							<v-btn v-if="!vermas" text @click="vermas = true">ver más...</v-btn>
						</template>
						<template v-else>
							<v-row align="center" justify="center">
								<v-progress-circular indeterminate color="primary" v-if="searching"/>
							</v-row>
						</template>

						<div>
							(S) Síntoma
							(FR) Antecedente o Factor de Riesgo
							(C) Condición
						</div>
					</v-card-text>
					<v-card-actions v-if="!fullscreen">
						<v-spacer/>
						<v-btn outlined color="primary" :disabled="!valid" @click="siguiente">Siguiente</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import {fetchOptions, refresh_token} from "@/actions/actions";
import {mapActions, mapGetters} from "vuex";
import * as types from "@/store/types";
import {ADD_MULTIPLE_EVIDENCE} from "@/store/types";

let controller = {};

export default {

	name: 'buscadorSintomas',

	data: () => ({
		sintomasForm: false,
		sintomas:[],
		sintoma: "",
		sintoma_old:"",
		searchResult: {},
		showResults: false,
		labels: {},
		searching: false,
		vermas: false,
	}),
	props:{
		interview: { required: true },
		fullscreen: {default:false, type: Boolean}
	},
	computed:{
		...mapGetters({ api_url: types.API_URL }),
		searchResultFilter(){
			if(this.vermas) { return _.unionWith(this.searchResult.main, this.searchResult.secondary, _.isEqual) }
			else return this.searchResult.main
		},
		valid(){
			const check = _.find(this.sintomas, function (o) {
				return /s_/.test(o.id)
			})

			return !!check
		}
	},
	watch:{
		sintoma: function(){ this.showResults = false },
	},
	methods:{
		...mapActions({addMultipleEvidence:ADD_MULTIPLE_EVIDENCE}),

		buscar(){
			if(this.sintoma && this.sintoma.length >= 3 ){
				this.searching = true;
				this.searchResult = {}
				if(typeof controller !== 'undefined' && typeof controller[this.sintoma_old] !== 'undefined') controller[this.sintoma_old].abort()
				this.sintoma_old = this.sintoma;

				controller[this.sintoma] = new AbortController();
				const { signal } = controller[this.sintoma];

				this.showResults = false
				const data={
					mode: "search",
					text: this.sintoma
				}

				fetch(`${this.api_url}interview/${this.interview}`, fetchOptions({method: "POST", auth, body: data, signal: signal}))
					.then( response =>{ if(response.ok) return response.json(); else throw response.text(); })
					.then( response => {
						this.searchResult.main = _.slice(response.result,0,10);
						this.searchResult.secondary = _.slice(response.result,10,20);
						this.vermas = !this.searchResult.secondary.length > 0;
						this.searching = false;
						this.showResults = true;
					})
					.catch(e=>{
						if(e.name === "AbortError") { }
						else {
							Promise.resolve(e).then(error=>{
								console.log(error)
								if(JSON.parse(error).message === 'Unauthorized' ){ refresh_token(); this.buscar() }
							});
						}
					})
			}
			else{
				this.showResults = false
			}
		},
		addSintoma(index){
			if(this.fullscreen) this.$emit('input', this.searchResultFilter[index].id);
			else{
				const id= this.searchResultFilter[index].id
				this.sintomas.push( {id, choice_id: "present", source:"initial"} )
				this.labels[id]= this.searchResultFilter[index].label
			}
		},
		removeSintoma(index){
			this.sintomas.splice( index, 1 )
		},
		siguiente(){
			this.addMultipleEvidence(this.sintomas).then(()=> {
				Event.$emit('siguienteInterview', 'sugerencias')
			})
		},

		checkType(id){
			const concept = id.charAt(0)
			switch (concept){
				case 's': return "S"
				case 'p': return "FR"
				case 'c': return "C"

			}
		}
	}
}
</script>
