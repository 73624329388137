<template>
	<v-row justify="center" class="fill-height" align="center">
		<v-col cols="11" sm="10" md="8">
			<v-img class="mx-auto" :src="require('@/assets/images/logo.png')" max-width="80%" max-height="400px" contain/>
			<v-card>
				<v-card-title>
					<div class="secondary--text word-break">Bienvenido a SOPhIA de {{cliente_name}}</div>
				</v-card-title>
				<v-form id="adminLoginForm" ref="adminLoginForm">
					<v-card-text>
							<v-row no-gutters>
								<v-col cols="12">
									<v-text-field outlined v-model="email" label="Email" @keypress.enter.prevent="login"/>
								</v-col>
								<v-col cols="12">
									<v-text-field outlined v-model="password" type="password" label="Contraseña" @keypress.enter.prevent="login"/>
								</v-col>
								<v-col cols-="12" v-if="error">
									<v-alert border="bottom" colored-border type="error" elevation="2">
										{{errorMessage}}
									</v-alert>
								</v-col>
							</v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn class="mx-auto primary" block @click.prevent="login">ingresar</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
			<v-dialog v-model="resetPasswordDialog" max-width="500px">
				<template v-slot:activator="{on}">
					<a v-on="on">Olvide mi contraseña</a>
				</template>

				<v-card>
					<v-card-title>
						Resetear contraseña
						<v-spacer/>
						<v-btn icon @click="resetPasswordDialog=false"><v-icon color="red">fal fa-times</v-icon></v-btn>
					</v-card-title>
					<v-card-text>
						<v-text-field label="Ingrese su correo" v-model="email"/>
					</v-card-text>
					<v-card-actions>
						<v-btn outlined @click="sendPasswordResetEmail">restablecer contraseña</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-col>
	</v-row>
</template>

<script>
	import {mapGetters} from 'vuex'
	import * as types from '@/store/types'
	import validationRules from "@/actions/validationRules";
	import {CLIENTE_NAME} from "@/store/types";

	export default {
		name: "Login",
		data(){
			return {
				valid: true,
				error: false,
				errorMessage:"No se encuentra usuario o contraseña no coincide.",
				politicas: false,
				politica: '',
				tipo_identificacion: '',
				tipo_identificacions: [{value:4, text:"Cédula Ciudadania"}, {value:"5", text:"Cédula Extranjería"}, {value:"P", text:"Pasaporte"}, {value:"6", text:"NIT"}, {value:"D", text:"Carné Diplomático"},{value:"S", text:"Salvoconducto"}],
				identificacion:'',

				rules: validationRules,

				loading: false,
				goTo: {name: 'marcoDashboard'},

				adminLoginModal: false ,
				adminLoginForm: false,
				email: "",
				password: "",
				resetPasswordDialog: false
			}
		},
		computed: {
			...mapGetters( { api_url: types.API_URL, cliente_name: CLIENTE_NAME }),
		},
		methods: {
			login(){
				if (this.$refs.adminLoginForm.validate()) {
					auth.signInWithEmailAndPassword(this.email, this.password)
						.then(user => {
							auth.currentUser.getIdTokenResult().then( tokenResult => {
								this.$router.push({name: 'Home'}).catch(e=>{});

							})
						})
						.catch(error =>{
							console.log(error);
							this.error = true;
							if(error.code === 'auth/user-disabled'){
								this.errorMessage = 'Usuario deshabilitado, contacte al administrador.'
							}else{
								this.errorMessage = 'No se encuentra usuario o contraseña no coincide.'
							}
						})
				}
			},
			sendPasswordResetEmail(email){
				auth.sendPasswordResetEmail(this.email)
					.then(() => {
						toast.fire({
							text:'Se ha enviado un correo para reestablecer la constraseña',
							icon:'success'
						})
						this.resetPasswordDialog = false
					})
					.catch((error) => {
						toast.fire({
							text:'SHa ocurrido un error, por favor, intentelo más tarde',
							icon:'error'
						})
					});
			}

		}
	}
</script>

<style>

</style>
