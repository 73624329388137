<template>
	<v-container>
		<v-row justify="center">
			<img :src="require('@/assets/images/logo.png')" style="max-width:350px; width:80% "/>
		</v-row>
		<div class="text-h5 mt-5 primary--text font-weight-bold">Bienvenido {{ saludo[ubicacion] ?? defaultSaludo }}
			<v-btn icon @click="read(`paciente_datos`)" v-if="audioDescription">
				<v-icon>fas fa-volume</v-icon>
			</v-btn>
		</div>

		<v-form ref="iniciarForm">
			<v-row>
				<v-col cols="12">Por favor verifica y completa la siguiente información para iniciar.</v-col>
				<v-col cols="12" md="6">
					<v-text-field outlined label="Primer Nombre *" v-model="user.primerNombre"
								  placeholder="Escribe tu nombre" :rules="[required]"
								  @mouseover.native="read('Primer nombre')" @mouseleave.native="read('stop')"/>
				</v-col><v-col cols="12" md="6">
					<v-text-field outlined label="Segundo Nombre" v-model="user.segundoNombre"
								  placeholder="Escribe tu segundo nombre"
								  @mouseover.native="read('Segundo nombre')" @mouseleave.native="read('stop')"/>
				</v-col>
				<v-col  cols="12" md="6">
					<v-text-field outlined label="Primer Apellido*" v-model="user.primerApellido"
								  placeholder="Escribe tu primer apellido" :rules="[required]"
								  @mouseover.native="read('Primer apellido')" @mouseleave.native="read('stop')"/>
				</v-col>
				<v-col  cols="12" md="6">
					<v-text-field outlined label="Segundo Apellido" v-model="user.segundoApellido"
								  placeholder="Escribe tu segundo apellido"
								  @mouseover.native="read('Segundo apellido')" @mouseleave.native="read('stop')"/>
				</v-col>
				<v-col  cols="12" md="6">
					<v-select outlined label=" Tipo Identificación" v-model="user.tipoIdentificacion"
							  :items="['CC','TI/NUIP','CE','PP','NIT']"
							  :rules="[required]"
							  @mouseover.native="read('Tipo de identificación')" @mouseleave.native="read('stop')"/>
				</v-col><v-col  cols="12" md="6">
					<v-text-field outlined label="Identificación" v-model="user.identificacion"
								  placeholder="Escribe tu número identificación" :rules="[required]"
								  @mouseover.native="read('Número de identificación')" @mouseleave.native="read('stop')"/>
				</v-col>
				<v-col  cols="12" md="6">
					<v-text-field outlined label="Teléfono de contacto" v-model="user.telefono"
								  placeholder="Por ingrese un número telefónico de contacto" :rules="ubicacion==='remoto'?[required]:[]"
								  @mouseover.native="read('teléfono de contacto')" @mouseleave.native="read('stop')"/>
				</v-col>
				<v-col  cols="12" md="4">
					<v-menu ref="menuFechaNacimiento" v-model="menuFechaNacimiento"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field outlined
								v-model="user.fechaNacimiento"
								label="Fecha de Nacimiento"
								readonly
								v-bind="attrs"
								v-on="on"
							  @mouseover.native="read('Fecha de nacimiento')" @mouseleave.native="read('stop')"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="user.fechaNacimiento"
							:active-picker.sync="activePicker"
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1920-01-01"
							@change="save"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col  cols="12" md="2">
					<v-text-field outlined label="Edad" v-model.number="user.edad" readonly disabled />
				</v-col>
				<v-col  cols="12" md="6">
					<v-select outlined label="Sexo" v-model="user.sexo"
							  :items="[{value: 'f', text: 'Femenino'},{value: 'm', text: 'Masculino'}]"
							  placeholder="Por favor especifica tu sexo" :rules="[required]"
							  @mouseover.native="read('sexo')" @mouseleave.native="read('stop')"/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-checkbox v-model="user.discapacidad"
								label="Cuento con certificado de discapacidad (Físico o mental)" hide-details
								@mouseover.native="read('Cuento con certificado de discapacidad (Físico o mental)')" @mouseleave.native="read('stop')"/>
					<v-checkbox v-model="user.cancer"
								label="Tengo cancer y me encuentro actualmente en tratamiento" hide-details
								@mouseover.native="read('Tengo cancer y me encuentro actualmente en tratamiento')" @mouseleave.native="read('stop')"/>
				</v-col>
			</v-row>
		</v-form>
		<v-row justify="center" align="center" class="flex-column mt-5">
			<v-btn @click="iniciar(true)" outlined x-large
				   @mouseover.native="read('continuar')" @mouseleave.native="read('stop')">continuar</v-btn>
			<v-btn text small  class="mt-2" @click="iniciar(true, false)" v-if="ubicacion==='sala'">
				<div class="text-capitalize">No deseo realizar la evaluación</div>
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import * as types from "@/store/types";
import {audioDescriptionMessages, saludo} from "../../../messages";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {createCollection, fetchOptions, refresh_token, updateCollection} from "@/actions/actions";
import moment from "moment";
import {
	API_URL,
	AUDIO_DESCRIPTION,
	INTERVIEW_USER,
	INTERVIEW_USER_ID,
	MUTATION_INTERVIEW_USER, SEDE,
	SET_RESULTADOS,
	START_INTERVIEW,
	UBICACION
} from "@/store/types";

export default {
	name: "DatosPaciente",
	data:()=>({
		ageRules: v=>v>=2||'El paciente debe tener al menos 2 años para poder usar la herramienta',
		required: v=>!!v||'El campo es obligatorio',
		activePicker: 'YEAR',
		date: null,
		menuFechaNacimiento: false,

		saludo,
		defaultSaludo: 'a nuestro servicio de Teleorientación',
		interviews: []
	}),
	async created(){
		this.user = this.interviewUser
		if(this.user.nombre) {
			this.user.primerNombre = this.user.nombre
			delete this.user.nombre
		}
		if(this.user.apellidos) {
			this.user.primerApellido = this.user.apellidos
			delete this.user.apellidos
		}

		const fbInterviews = await db.collection('interviews').where('paciente_id', '==', this.interviewUserId).get();

		if(!fbInterviews.empty){
			fbInterviews.forEach( inter => {
				this.interviews.push( {id: inter.id, ...inter.data() } )
			} )
		}

		this.interviews = _.sortBy(this.interviews, 'date')
	},
	computed:{
		...mapGetters({
			audioDescription: AUDIO_DESCRIPTION,
			api_url: API_URL,
			ubicacion: UBICACION,
			interviewUser: INTERVIEW_USER,
			interviewUserId: INTERVIEW_USER_ID,
			sede: SEDE
		}),
		user: {
			get(){ return this.interviewUser },
			set(value){ this.setUser(value) }
		}
	},
	watch:{
		menuFechaNacimiento (val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		},
	},
	methods:{
		...mapMutations({setUser: MUTATION_INTERVIEW_USER}),
		...mapActions({startInterview: START_INTERVIEW, resultados: SET_RESULTADOS}),

		iniciar: async function (express, evaluar = true){
			if(this.$refs.iniciarForm.validate()){
				if(!express) { return this.$router.push({name: 'Start'}) }
				try {
					cargando(true);
                    this.user.identificacion = _.trim(this.user.identificacion)
					await updateCollection('pacientes', this.interviewUserId, this.interviewUser)

					let interviewData ={
						user: this.user,
						paciente_id: this.interviewUserId,
						ubicacion: this.ubicacion,
						date: Date.now(),
						terminada: false,
						fuente: 'paciente',
						admitido: false,
						evaluacion_triage: false,
						sophia: true,
						atendido: false,
						sede: this.sede || 1
					}


					if(evaluar){
						const response =await fetch(`${this.api_url}interview/start`, fetchOptions({method: 'POST', auth, body: {
								age: this.interviewUser.edad,
								sex: this.interviewUser.sexo,
								express: true
							}
						}))

						if(!response.ok) throw new Error( await response.text() );
						const responseData = await response.json();

						moment.locale('es-mx');

						interviewData.id = responseData.entrevista

						const firebaseId = await createCollection('interviews', interviewData)

						this.startInterview({
							firebaseId,
							entrevista: responseData.entrevista,
							evidence: responseData.evidence,
						})


						cargando(false);

						const route = this.ubicacion === 'signosVitales' ? 'SignosVitales' : 'Interview'

						return this.$router.push({
							name: route,
							params: {
								interview: responseData.entrevista,
								riskFactors: responseData.factores_riesgo
							}
						})
					}
					else{

						interviewData.terminada = true
						interviewData.triage_level = 5
						interviewData.sophia = false
						const firebaseId = await createCollection('interviews', interviewData)
						this.startInterview({ firebaseId })
						this.resultados( { triage_level: 5 } )

						cargando(false);

						return this.$router.push({
							name: 'Resultados',
							params: {}
						})
					}
				}
				catch(e){
					cargando(false);
					Promise.resolve(e).then(error=>{
						if(JSON.parse(error.message).message === 'Unauthorized'){
							refresh_token();
							return this.iniciar(express)
						}
					})
				}
			}
		},
		read(item){ readDescription(item) },
		save (date) {
			this.$refs.menuFechaNacimiento.save(date)
			this.user.edad = moment().diff(date, 'years');
		},
	}
}
</script>

<style scoped>

</style>
