<template>
	<v-form v-model="dataForm" ref="dataForm">
		<v-row justify="center" >
			<v-col cols="11" md="10">
				<h2 class="secondary--text">Calcular Indice de Masa Corporal (IMC)</h2>
			</v-col>
			<v-col cols="11" md="10">
				<v-card>
					<v-card-text>
						<v-row no-gutters>
							<v-col cols="8">
								<v-text-field v-model.number="value.weight" label="Peso" type="number" :rules="rules.numero" outlined clearable/>
							</v-col>
							<v-col cols="4">
								<v-select :items="weight_units" v-model="value.weight_unit" label="Unidad" :rules="rules.required" outlined clearable/>
							</v-col>
							<v-col cols="8">
								<v-text-field v-model.number="value.height" label="Altura" type="number" :rules="rules.numero" outlined clearable/>
							</v-col>
							<v-col cols="4">
								<v-select :items="height_units" v-model="value.height_unit" label="Unidad" :rules="rules.required" outlined clearable/>
							</v-col>
						</v-row>

						<v-row v-if="imc.value > 0">
							<v-col>
								<h2 :class="imc.color">Su IMC es de {{imc.value}}</h2>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn outlined color="primary" @click="siguiente">Siguiente</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import validationRules from "@/actions/validationRules";

export default {
	name: "StartData",
	props: { value: {required: true} },
	data:()=>({
		dataForm: false,
		weight_units: ['g','kg','lb'],
		height_units: ['cm','m','in', 'ft'],
		rules: validationRules
	}),
	computed:{
		imc: function(){
			let peso;
			let altura;
			let imc = {value: "", color:"green--text"}
			switch (this.value.weight_unit){
				case 'g': peso = this.value.weight * 0.001; break;
				case 'lb': peso = this.value.weight * 0.453592; break;
				default: peso = this.value.weight; break;
			}
			switch (this.value.height_unit){
				case 'cm': altura = this.value.height * 0.01; break;
				case 'in': altura = this.value.height * 0.0254; break;
				case 'ft': altura = this.value.height * 0.3048; break;
				default: altura = this.value.height; break;
			}

			imc.value = Math.round(peso / Math.pow(altura, 2), 2 );
			if(imc.value > 30 || imc.value < 19 ) imc.color = 'red--text';
			return imc;
		}
	},
	methods: {
		siguiente(){
			Event.$emit('siguienteStart', 'viajes')
		}
	}
}
</script>

<style scoped>

</style>
